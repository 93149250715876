export default 

{
  dashboard: {
    available_balance: 'Available Balance',
    dost_pribyl: 'Available profit',
    valuta: '$',
    pribil_segodnya: 'Profit Today',
    add_cashback: 'Received cashback this month',
    wait_cashback: 'Cashback is still awaiting crediting.',
    sum_cashback: 'The amount of cashback you can fix is',
    new_cashback: 'from your deposit, you have accumulated profit, but there is not enough deposit to credit it. Replenish',
    implement_cashback: 'to realize ALL profit from checks',
    withdrawal: 'Withdraw',
    transfer: 'Transfer',
    Top_up_dep: 'Deposit',
    total_deposit: 'Total Deposit',
    withdrawn: 'Funds Withdrawn',
    accrued_cashback: 'Accrued from friends cashback',
    active_deposits: 'Active Deposits',
    team_profit: 'Referral Profit',
    account: 'Account',
    invest_plan: 'Investment Plans',
    accrued_market: 'Accrued from Marketplaces',
    accrued_go: 'GO Pool account accrual start March 1st',
    settings: 'Settings',
    fin_plan: 'My financial cashback plan',
    pribil_plan: 'Profit plan, deposit reactivation dates, actual profit',
    open_table: 'Open table',
    active_dep: 'Active deposits',
    profit_dep: 'Profit from this deposit will not be credited in the next calculation period. You can reactivate it by clicking ‘Enable’',
    loading: 'Loading...',
    work_dep: 'Working deposit, profit is being accrued on it',
    off_dep: 'Turn off deposit',
    on_dep: 'Enable',
    empty_deps: {
      '0':'There is no deposit yet, top up your account to get profit',
      '1':'Top Up'
    },
    send_schet: 'Send to account',
    in_progress: 'in the process of crediting',
    new_cicl: 'new cycle starts in',
    sum_day: 'days',
    date: 'date',
    new_period: 'new period starts tomorrow',
    lichniy_kabinet: 'Personal account',
    close_dep_info:'Do you want to disable the deposit?',
    deposit_active: { 
      '0':'Deposit',
      '1':'active',
      '2':'Attention! The effectiveness of this deposit has been reduced to ',
      '3':'Attention! The effectiveness of this deposit has been INCREASED to ',
      '4':'relative to the declared amount.',
    },
    support: 'Contact support to restore functionality',
    remaining_days: 'remaining until the end of the investment period',
    buttons: {
      stop:'Stop',
      rise_profit:'Increase Yield'
    },
    earn_more:{
      '0':'You can earn more',
      '1':'The profit from this deposit over ',
      '2':'days would have ranged from',
      '3': 'to',
      '4':'of',
      '5':'resulting',
      '6': ' in a net profit of at least',
      '7':' if this deposit had been active under the Quarter account. You now have the option to improve the performance of this deposit.',
    },
    close_in_advance:'Close the deposit in advance before the start of the new calculation period. The closed deposit will be transferred to available profit after the lock-up period expires.',
    confirm_action: 'Do you confirm your action?',
    withdraw_now: 'Withdraw the deposit now?',
    cashback_earned: 'Cashback Claimed'
    
  },
  topup: {
    safe_p2p: 'Safe P2P transaction',
    topup_card: 'Top up by card',
    topup_dep: 'Top up deposit with commission from 0.9% to 1.8%, credited within 30 minutes (sber, alpha, tinkoff, ozon, sbp and others)',
    choose: 'Choose',
    topup_crypto: 'Top up with cryptocurrency',
    topup_moment: 'Instant deposit with cryptocurrency USDT, ETH, BNB, TRX without commission in any network',
    topup_birja: 'Top up on MetaS exchange',
    topup_vvod: 'Deposit and withdraw',
    topup_nocom: 'commission free',
    other_country: 'Kazakhstan, Belarus, Russia, Moldova, Kyrgyzstan, Tajikistan, Uzbekistan, Turkey, and others',
    skoro: 'coming soon...'
  },
  history: {
    title: 'All profit transactions in a list',
    desc: 'Here you can see all the profit transactions on your deposits, as well as profit from you team',
    tt: 'Trasactions',
    success: 'Success',
    p_purpose: 'Payment purpose',
    curr_account: 'Current account',
    status: 'Status',

  },
  withdrawn: {
    my_schet: 'My accounts',
    all_history: 'View all history',
    raspred_pribil: 'Profit distribution',
    valuta: '$',
    dostupno: 'Available',
    last_profit: 'Recent Account Credits',
    reinvest: 'Reinvestment',
    account_balance: 'cur. deposit',
    loading: 'Loading',
    obrabotka: 'processing',
    vivesti: 'Withdraw',
    greencomm:'You have a COMFREE checkmark, deposit and withdrawal fees (except TRC) – 0%.',
    checkagain:'Please check the withdrawal details, the amount plus the fee may exceed the available balance',
    in_reinvest: 'reinvestment (deposit)',
    download: 'Download',
    poisk_schetov: 'searching for accounts...',
    zayavki: 'Withdrawal and reinvestment requests',
    no_zayavok: 'No pending requests',
    suc_trans: 'Successful transactions',
    otsut_trans: 'No transactions on the account',
    vivod: 'withdraw',
    vvod: 'deposit',
    reinvesti: '(reinvestment)',
    deposit: 'Account deposit:',
    vivod_sredstv: 'Withdrawal of funds',
    vibor_schet: 'Select the account to withdraw from',
    dostupno_ot: 'available for withdrawal from',
    do: 'to',
    na_etom_schete: 'there are no funds available for withdrawal on this account or the amount is less than 1$',
    metod: 'Select the withdrawal method',
    card: 'Bank Account',
    spb: 'SBP',
    spb_add: '(Phone number)',
    crypto: 'Cryptocurrency',
    plata: 'Specify payment details',
    get_crypto: 'Select cryptocurrency',
    network: 'Select network for transfer',
    commission: 'Commission:',
    sum: 'Enter the amount to calculate the commission',
    bank: "Bank",
    fio: "Name Surname",
    number: "Phone Number",
    nazv_bank: "Bank Name",
    name_owmner: "Owner's Name",
    number_card: "Card Number",
    numberwallet: "Wallet number",
    sum_withdrow: "Withdrawal amount",
    vedite: 'Enter the correct recipient Bank name',
    check_sum: 'Check withdrawal amount',
    check_dannie: 'Check card owner details',
    check_phone: 'Enter a correct phone number for transfer',
    check_number: 'Enter a correct card number',
    check_wallet: 'Enter a correct wallet number',
    info_sum:'The amount exceeds the allowable value',
    warning1: 'Attention! To avoid bank blocks (Federal Law 115), the recommended withdrawal amount should not exceed 50,000 rubles per day. Please divide the transaction amount into several parts, use cryptocurrency, or contact your manager.',
    not_max: 'Withdrawal amount cannot be less than 1$',
    withd_request_succ: 'Transaction sent. If the withdrawal appears in the processing list, it means everything was successful: the request has been submitted and is under review. Processing takes several hours, and according to regulations (up to 24 hours), the funds will be sent to the specified address',
    withdrawal_request: "Withdrawal Request",
    reglament: 'Attention! Withdrawals take up to 24 hours according to regulations. The actual withdrawal time depends on the payment method, account status, and transaction amount – most often, withdrawals are processed within 2-3 hours.',
    new_req_soon: "You can submit a withdrawal request once per day. A new withdrawal request can be created in",
    date: 'date',
    close: 'Close',
    },
  
    tasks: {
      update: 'daily update',
      pereshet: 'attention recount occurs once a day',
      wait_zachislenie: 'waiting for crediting',
      valuta: '$',
      svodka: 'Summary',
      friend: 'Number of friends',
      chelovek: 'pers.',
      my_cashback: 'My cashback',
      earned_cashback: 'Earned from friends cashback',
      see_statis: 'See statistics',
      stat_pozhe: 'Statistics will be available later',
      partner: 'Partner program',
      esli_vi: 'If you invite friends, you not only',
      povish_cashback: 'get increased cashback',
      no_i: 'but also',
      procent: 'percentages',
      withcashback: 'from the cashback of your',
      friend_and_friend: 'friends and friends of your friends.',
      promocode: 'Promo code',
      qrcode: 'Or show an individual QR code for download',
      ref_ssilka: 'Or use a referral link through which the user can join the project',
      registration: 'Attention! Registration with your promo code gives the user an additional 1% to cashback from all his receipts',
      how_work: 'How does it work?',
      max_cashback: 'What is the maximum cashback I can get?',
      add_cashback: 'When you first register, your cashback will be 22%, you can increase it to 35% with a receipt.',
      do_doing: 'What should I do?',
      plus_cash: 'To increase cashback, you just need to invite your friends. Each invited friend increases your cashback by 1% (they should not be "fake" accounts, active accounts with a minimum consumption level of ~15,000 rubles are expected). So you need to invite 25 people for your cashback to become 35%.',
      obnovlenie_struk: 'How often is the structure data updated?',
      one_day: 'Once a day, the first update accrues profit for the previous day, and the second (the next day) credits it to the withdrawal account, after which the cycle repeats',
      s_obema: 'Will I make a profit from volume?',
      big_plus: 'But there is another huge plus: if you invite friends, you not only get increased cashback but also percentages from the deposits of your friends and friends of your friends.',
      ref_schet_val: 'There is money on the referral account, but it does not transfer to the Meta-Scanner account for withdrawal',
      ot_100: 'Money transfer to the withdrawal account is available for EACH level when the amount reaches 100 rubles',
      
      partner_program: 'Partner Program',
      desc_p:"If you invite friends, you not only receive increased profits but also earn a percentage of the profits from your friends and their friends.",
      read_det: 'Read more',
      invite: 'Share',
      link_for_web: 'Link for the web',
      link_for_tg: 'Link for Telegram',
      in_telegram: 'In Telegram App',
      code:'code',
      for_download: 'for download',
      show_individual:'Or provide your unique',
      promo_code: 'Promo Code',
      link: 'Link',
      desk_info_ref_link: {
        '0': 'Or use',
        '1': ' the referral link',
        '2': 'through which users can join the project.'
      },
      stat: 'Statistics',
      clicks_tg: 'Clicks on the link in TG',
      total_users:'Number of users in the team',
      volume_team: 'Structure volume',
      profit_from_team:'My profit from team growth',
      profit_from_team_passive: 'Passive income from team profit',
      upgrade_self_portfolio: 'Upgrade the efficiency of my portfolio',
      month: 'month',
      total_m: 'Total money',
  },
    statref: {
      vsego: 'Total:',
      chel: 'peop.',
      obem: 'Volume:',
      obem1: 'Volume',
      valuta: '$',
      valuta1: '$',
      statist: 'statistics updated ',
      menee_chasa: 'less than an hour ago',
      nazad: 'ago',
      update_dannih: 'data is updated once a day',
      level: 'Level',
      earned_obshii: 'Total earnings:',
      send_na_schet: 'Sent to account:',
      ottok: 'Outflow for 10 days:',
      wait_zachislenia: 'Waiting for crediting',
      deposit: 'Deposit:',
      hour: 'hr',
      vivel: 'Withdrawn in the last 10 days:',
      plan_vivod: 'Planned withdrawal:',
      reinvest: 'Reinvest:',
      date: 'Date:',
      proglasil: 'Invited',
      loading: 'Loading...',
      zagruzka: 'loading data...',
      obiem: 'Structure volume:',
      razmer: 'Structure size:',
      chelovek: 'people',
      copy_buffer: 'Copied to clipboard',
      no_copy: 'not copied',
      actual_data: 'Current data has been uploaded.',
      dohoddohod: 'Сompound earnings',
      empty_state:{
        '0': 'There are currently no users at level ',
        '1': 'We will monitor updates and notify you when they appear.'
      }

      },
    profile: {
      promocode: 'Promocode:',
      manager: 'Personal Manager',
      promo: 'PROMOCODE:',
      telegram: 'Telegram:',
      email: 'Email:',
      new_info: 'A lot of useful information is posted on the channel in tg',
      local_settings: 'Local Settings',
      country: 'Country',
      country_1: 'Russia',
      valuta_slovo: 'Currency',
      valuta: '$',
      language: 'Language',
      language_code: 'Eng',
      info_profile: 'Profile Information',
      finance: 'Finance',
      otchet: 'Weekly Report',
      security: 'Account Security',
      new_vhod: 'New Login',
      new_password: 'Password Change',
      safe: 'Safety',
      sbrosit: 'Reset',
      loading: 'loading...',
      new_pass: 'Change Password',
      st_password: 'old password',
      new_password1: 'new password',
      new_password_add: 'new password (again)',
      full_fio: 'First Name and Last Name',
      number_phone: 'for example, +123422233',
      phone:'Phone',
      fio: 'Name',
      compl_save: 'saving in progress...',
      save: 'Save',
      change_profile_info: 'Change Profile Info',
      manager_name: 'Your Personal Manager​',
      
    },
    signup_redirect: {
      new_acc: 'Create an account:',
      apply: 'I subscribe to special offers newsletter according to the',
      pravila: 'Terms of Service',
      nashei: 'and our',
      policy: 'Privacy Notice',
      complite_reg: 'Registration in progress...',
      login_in: 'Register',
      after_reg: 'After registration, the system will redirect you to the download page for the application. Enter the same authentication credentials to log in.',
      },
    signup: {
      new_acc: 'New Account',
      pretext: 'To complete the registration, please fill out the form below',
      apply: 'By registering, you confirm that you agree to the',
      pravila: 'Terms of Service',
      nashei: 'and our',
      policy: 'Privacy Policy',
      complite_reg: 'Registration in progress...',
      login_in: 'Register',
      load_acc: 'Already have an account?',
      vvoiti: 'Log in',
      vashe_imya: 'Your name',
      email: 'Email',
      telephone: 'Telegram / Whatsapp / Phone',
      password: 'Password',
      add_password: 'Repeat password',
      promocode: 'Promo code (optional)'

    },
    sign_monitor: {
      welcome: 'Welcome',
      email_pass: 'Enter your Email and Password to log in to your account',
      email: 'Email',
      parol: 'Password',
      remember: 'Remember browser',
      loading: 'Loading...',
      vhod: 'Log in',
      no_acc: "Don't have an account?",
      registration: 'Register',
      dont_passw: 'Forgot your password?',
      restore: 'Restore'
    },
    sign_in: {
      welcome: 'Welcome',
      email_pass: 'Enter your Email and Password to log in to your account',
      email: 'Email or phone',
      parol: 'Password',
      remember: 'Remember browser',
      loading: 'Loading...',
      vhod: 'Log in',
      no_acc: "Don't have an account?",
      registration: 'Register',
      dont_passw: 'Forgot your password?',
      restore: 'Restore',
      telegram_auth: {
        0:'If you signed in using the Telegram app, a password was created automatically. Please ',
        1:'click the link to retrieve it.',
        2:'This will open the Telegram bot app, where you can get your password.'
      }
    },
    restore: {
      restore_pass: 'Password Recovery',
      vvod_email: {
        '1': 'Enter your email address or phone number in the appropriate field.',
	      '2':'Click the “Recover Password” button.',
	      '3': 'You will receive a message with instructions to reset your password by email or telegram bot'
      },
      email: 'Email or phone',
      new_pass: 'Send new password'
    },

    ordered: {
      no_tovar: 'empty list / no items here',
      check: 'Receipt',
      na: 'for',
      valuta: '$',
      cashback: 'cashback',
      valutas: '$'
    },
    telegram: {
      telegram_app: 'Telegram App',
      use_tg: 'Use Telegram to upload receipts and confirm actions on your account.',
      podkl: 'Connect'
    },
    youtube: {
      opublikov: 'Published',
      day_ago: 'days ago'
    },
    
    reinvestirovat: {
      reinvest: 'Transfer',
      with_pribil: 'From Balance',
      dostupno: 'Available',
      valuta: '$',
      na_deposit: 'Deposit To',
      deposit: 'deposit:',
      sum: 'Amount',
      sum_exp: 'The amount exceeds the allowable value',
      warning:"Reinvestment (to day, week, quarter accs) is equivalent to a commission-free deposit. A new reinvestment creates a separate transaction in the system, which doesn't affect the term of other deposits, and its profit is calculated independently.",
      reinvest_title: 'Internal transfer'
    },

    top_up: {
      popolnenie: 'Deposit',
      schet_popolneniya: 'Select the account to top up',
      minimum: 'minimum deposit from',
      valuta: '$',
      promo: '+ promo code',
      loading: 'Loading...',
      zagruzka: 'Loading...',
      popolnit: 'Top up',
      summa: 'Deposit amount'
    },

    topup_crypto: {
      crypto: 'Cryptocurrency',
      curr_balance:'Current Balance',
      acc: 'Select a Funding Account',
      alternative: 'Using Bank Acc',
      alternative_desc: 'USD, EUR, IDR and other',
      popolnenie: 'Deposit',
      schet_popolneniya: 'Select the account to top up',
      paymentMetod: 'Select a Funding Options',
      choose_crypto: 'Select asset',
      valuta: '$',
      choose_network: 'Select network',
      see_address: 'Show address',
      loading: 'Loading...',
      warning: 'Attention! Send only',
      address: 'to this address. Make sure you are making a transfer on the',
      copy: 'Copy',
      minim_dep: 'Minimum deposit ',
      usd_zachis: 'USD The deposit will be credited to your account after 15 network confirmations, if necessary, you can always contact technical support. We are not responsible if you send assets via the wrong network'
      },

      sidenav: {
        main: 'Dashboard',
        top_up: 'Deposit',
        sum: 'Amount',
        cashback: 'Cashback',
        withdrawn: 'Transactions',
        task: 'Partner Program',
        profile: 'Settings',
        report: 'Everyday Reports',
        invest_plan:'Invest Plans'
      },
      toast:{
        crypto_address_get: "Your Wallet’s Personal Address Has Been Received",
        unknown_error: "Unknown error. Please contact support.",
        deposit_status_changed: "Deposit status has been changed",
        new_password_sent: "A new password has been sent to your email",
        check_spam: "Check your spam folder",
        success_login: "Successful login",
        succsess_reg: "Registration completed successfully",
        profile_info_updated: "Profile information has been updated",
        reinvest_request_created: "Reinvestment request has been created",
        open_payment_window: "Opening payment window",
        auth_befor_pay: "You need to log in before making a payment",
        withdraw_req_created: "Withdrawal request has been created",
        request_applied: "Request has been applied",
        item_deleted_restart: "Item has been deleted. Please reload tis page",
        login_to_account: "Log in to your account",
      },
      invest:{
        investment_programs: 'Investment Plans',
        long_desc: 'This is the investment section, where we share our investment programs designed around various earning concepts. We are committed not only to sustainable growth but also to creating opportunities for you. In each program, we work actively to generate income and are happy to share a portion of our profits with you. Join us and let your investments work for you!',
        profit_calculator: 'Profit Calculator',
        profit: 'profit calculated',
        bot_card:{
          limits: 'Limits',
          profitability: 'Profitability',
          lock_in:'Principal Lock-in Period',
          prof_frieq: 'Profit Payout Frequency'
        },
        dep_amount:'Deposit Amount',
        investment_dur:'Investment Duration',
        days:'days',

      },

      partners:{
        
      }
  

}
