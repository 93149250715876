<template>
  <div class="fixed-plugin" >

    <div class="card " style="background-color: #1B1B1B; overflow-y: scroll; overflow-x: hidden !important; border-left:1px solid rgb(228, 228, 228);">
      <div @click="close_window" style="position:absolute; right:20px;top:20px; cursor:pointer;" >
        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M23.6107 2.91675H11.3899C6.08158 2.91675 2.91699 6.08133 2.91699 11.3897V23.5959C2.91699 28.9188 6.08158 32.0834 11.3899 32.0834H23.5962C28.9045 32.0834 32.0691 28.9188 32.0691 23.6105V11.3897C32.0837 6.08133 28.9191 2.91675 23.6107 2.91675ZM22.4003 20.8542C22.8232 21.2772 22.8232 21.9772 22.4003 22.4001C22.1816 22.6188 21.9045 22.7209 21.6274 22.7209C21.3503 22.7209 21.0732 22.6188 20.8545 22.4001L17.5003 19.0459L14.1462 22.4001C13.9274 22.6188 13.6503 22.7209 13.3732 22.7209C13.0962 22.7209 12.8191 22.6188 12.6003 22.4001C12.1774 21.9772 12.1774 21.2772 12.6003 20.8542L15.9545 17.5001L12.6003 14.1459C12.1774 13.723 12.1774 13.023 12.6003 12.6001C13.0232 12.1772 13.7232 12.1772 14.1462 12.6001L17.5003 15.9542L20.8545 12.6001C21.2774 12.1772 21.9774 12.1772 22.4003 12.6001C22.8232 13.023 22.8232 13.723 22.4003 14.1459L19.0462 17.5001L22.4003 20.8542Z" fill="white"/>
          </svg>
      </div>

      <section class="lav-replenishment lav-crypt" style="margin-bottom:100px;">
        <h1 class="lav-replenishment-tile">{{$t('topup_crypto.popolnenie') }}</h1>
        <div class="w-100 mt-2">
          <p class="lav-replenishment-subtitle ">{{$t('topup_crypto.paymentMetod') }}</p>
        </div>
  
        <div class="lav-replenishment__period mt-0"> 
         
          <!-- <div  class="lav-replenishment__period_item  w-45 "  @click="openfiat()">
              <div class="lav-replenishment__period_item-name " style="border: none;">Через Банк</div>
          </div> -->
          <div  class="lav-replenishment__period_item height50px  lav-replenishment-select w-45 justify-content-center">
            <div class="lav-replenishment__period_item-name"  style="border: none;">{{$t('topup_crypto.crypto') }}</div>
        </div>

          <div   class=" d-flex lav-replenishment__period_item-union w-50 height50px" style="opacity:0.3">
            <div class="text-start ps-2 pt-3 lav-replenishment__period_item-name-union text-light ">
              <span class="text-bold" style="font-size: 14px;">{{$t('topup_crypto.alternative') }}</span>
              <p class="" style="font-size: 10px;">
                {{$t('topup_crypto.alternative_desc') }} 
              </p></div>
            <div class="lav-replenishment__period_item-soon ">soon</div>
        </div>
          
  
        </div>
        <div class="w-100 mt-4">
          <p class="lav-replenishment-subtitle"> {{$t('topup_crypto.acc') }} </p>
        </div>
        <!-- <div   class=" mt-2 w-100 d-flex lav-replenishment__period_item-union" style="opacity:0.3">
          <div class="lav-replenishment__period_item-name-union text-light ">Единый торговый аккаунт <br> <span class="" style="font-size: 10px;">переводы, реинвестирование, ниже комиссии</span></div>
          <div class="lav-replenishment__period_item-sum-union text-light ">0 руб</div>
          <div class="lav-replenishment__period_item-soon ">скоро</div>
      </div> -->
        <div class="lav-replenishment__period">
          <template v-for="(item,index) in userBots"   :key="item.slug">
          <div   @click="robot = item;check_index=index" 
              v-if="item.show_in_deposit_section"
              :class="[index === check_index ? 'lav-replenishment-select': null]"
              class="lav-replenishment__period_item w-30">
              <div class="lav-replenishment__period_item-name w-70">{{item.name}}</div>
              <div class="lav-replenishment__period_item-sum text-cetner">  
                
                <span style="opacity: 0.6;">{{$t('topup_crypto.curr_balance') }} </span>
                <br>
                <strong style="font-size: 16px;">{{item.deposits.toLocaleString('en')}} {{$t('top_up.valuta') }}</strong>
                </div>
          </div>
        </template>
        </div>
        

        <div class="lav-sum-replenishment">
           <h2 class="lav-sum-replenishment-title"> {{$t('topup_crypto.choose_crypto') }}</h2>
           <div class="lav-sum-replenishment_choice">
            <template v-bind:key="item.slug" v-for="(item) in cryptoAssets" >
          
              <div 
                  v-if="asset===item?.slug" 
                  class="lav-sum-replenishment_choice-item lav-replenishment-select" >
                  {{item.name}}
                </div>
                <div 
                  v-else @click="asset=item.slug" 
                  class="lav-sum-replenishment_choice-item " >
                  {{item.name}}
                </div>
                
            </template>
            
           </div>
        </div>
  
        <div class="lav-sum-replenishment">
           <h2 class="lav-sum-replenishment-title"> {{$t('topup_crypto.choose_network') }}</h2>
           <div class="lav-sum-replenishment_choice">
            <template v-bind:key="item.slug" v-for="(item) in networks" >
              <div 
                v-if="network===item?.slug" 
                class="lav-sum-replenishment_choice-item lav-replenishment-select" 
                >
                {{item.name}} 
              </div>
              <div 
                v-else @click="network=item.slug" 
                class="lav-sum-replenishment_choice-item" 
                >
                 {{item.name}} 
              </div>
    
    
            </template>

           </div>
        </div>
  
        
          <div 
            v-if="!is_show_address"
            class="mt-3 text-center align-items-center d-flex justify-content-center " 
            style="min-height:300px; background-size: cover; width: 100%;"
            @click="get_address()"
            :style="{
                      backgroundImage:
                        'url(' +
                        require('@/assets/img/bg-blur.png') +
                        ')',
                    }"
            >
  
            <div v-if="!loading"> 
                <a class="lav-sum-replenishment_choice-item lav-replenishment-select" href="#"> {{$t('topup_crypto.see_address') }}</a>
            </div>
  
            <div class="spinner-grow " role="status" v-else>
              <span class="visually-hidden">{{$t('topup_crypto.loading') }}</span>
            </div>
           
  
          </div>
  
          <div 
            v-else
            class="  mt-3 text-center align-items-center justify-content-center" 
            role="alert" 
            style="min-height:200px; background-size: contain; "
           
            >
  
  
            
              <div class="text-start mt-4">
                  <img class="alex-qr-code" :src="`https://backend.l1st.online${cryptoAddress.qr_address}`" alt="">
                  <div  @click="copyAddress(cryptoAddress.address)"  class="text-light mt-4 alex-qr-code_btn-copy">
                    <img  src="../assets/alex-image/copy.svg" alt="">
                    {{$t('topup_crypto.copy') }}</div>
                  <p class="text-light" style=" width: 80%; ">{{cryptoAddress.address}}</p>
              </div>
              
              <div style="border-bottom: 1px dotted #fff;" class="mt-2 mb-2"></div>
              <img src="../assets/alex-image/attention-logo.svg" alt="">
              <img src="../assets/alex-image/attention-logo.svg" alt="">
              <img src="../assets/alex-image/attention-logo.svg" alt="">
              <p class="lav-replenishment-subtitle">{{$t('topup_crypto.warning') }}
                 <span class="text-bold">{{cryptoAddress.asset}}</span>
                 {{$t('topup_crypto.address') }}
                <span class="text-bold">{{cryptoAddress.network_name}}</span>
            </p>
            <p class="lav-replenishment-subtitle">
              {{$t('topup_crypto.minim_dep') }} {{minValue.toLocaleString('en')}}  {{$t('topup_crypto.usd_zachis') }}
            </p>
          </div>

    
     </section>
 




  
  <!-- <a ata-bs-toggle="collapse" style=" cursor:pointer" class="mt-4" @click="toggle_promocode()">
    <span class="badge badge-sm bg-gradient-dark" style="border-radius: 10px; width:auto; height:auto; font-size: 10px;">+ промокод</span>
  </a>
  <div class="mt-2" v-if="showPromoCode">
    <div class="alex-input__box">
        <input 
        style="height:15px; padding: 5px 10px 5px 10px; font-size: 14px; width: 100%;"
        type="number"
        :disabled="is_show_address"
        v-model="promocode"
        class="form-control" 
        placeholder="Промокод">
      </div>
  </div> -->
    
  

     

      
    </div>
  </div>
</template>

<script>
// import SoftRadio from "@/components/SoftRadio.vue";
// import SoftInput from "@/components/SoftInput.vue";
import { mapMutations, mapActions,mapGetters } from "vuex";
// import Close from "../components/Icon/Close.vue";

// import { copyText } from 'vue3-clipboard'
export default {
  name: "configurator",
  props: ["toggle"],
  data() {
    return {
      fixedKey: "",
      robot_slug: 'alpha',
      asset:'usdt',
      showPromoCode: false,
      promocode: '',
      is_show_address:false,
      loading:false,
      network: 'bep20',
      check_index:0,
      price: 5000,
      robot:{name: "Бонсай",slug:"alpha", revenue: "от 0.88% до 0.98%", minimum:1,maximum:499,init:true, },
    };
  },
  components: {
    // SoftRadio,
    // Close,
    // copyText,
    // SoftInput
  },
  watch:{
    network(val){
      console.log(val)
      this.$store.state.cryptoAddress = {}
      this.loading = false
      this.is_show_address= false
    },
    robot(val){
      console.log(val)
      this.$store.state.cryptoAddress = {}
      this.loading = false
      this.is_show_address= false
    },
    asset(val){
      console.log(val)
      this.$store.state.cryptoAddress = {}
      this.loading = false
      this.is_show_address= false
    }
    
  },
  methods: {
    ...mapMutations(["navbarMinimize", "sidebarType", "navbarFixed"]),
    ...mapActions(["toggleSidebarColor","getUserData","getCryptoAssets", "getAddressCrypto"]),
    get_address (){
      var data = {}
      data['asset'] = this.asset;
      if (this.network.length<3) {
        this.$toast.error('Выберите сеть для перевода');
        return 
      }
      data['network'] = this.network;
      data['robot'] = this.robot.slug;
      data['promocode'] = this.promocode;
      this.loading = true;
      this.getAddressCrypto({vm:this,data:data})
    },
    toggle_promocode(){
      this.showPromoCode = !this.showPromoCode;
    },
    copy() {
      this.$refs.clone.focus();
      document.execCommand('copy');
      this.$toast.success('Скопировано!');

    
      
    },
    close_window(){
      console.log('close window')
      this.$store.state.topUpCrypto = false;
    },
    openfiat(){
      this.$store.state.topUpCrypto = false;
      this.$store.state.topUpOwn = true;
    },
    
    async copyAddress(mytext) {
      try {
        await navigator.clipboard.writeText(mytext);
        this.$toast.success(this.$t('statref.copy_buffer'), { });
      } catch($e) {
        this.$toast.error(this.$t('statref.no_copy'), { });
      }
    },
    
    switchSelect() {
      this.userBots.forEach(element => {
        if (this.robot_slug === element.slug) this.robot = element
      })
    },
    sidebarColor(color = "warning") {
      document.querySelector("#sidenav-main").setAttribute("data-color", color);
      this.$store.state.mcolor = `card-background-mask-${color}`;
    },

    sidebarType(type) {
      this.toggleSidebarColor(type);
    },

    setNavbarFixed() {
      if (this.$route.name !== "Profile") {
        this.$store.state.isNavFixed = !this.$store.state.isNavFixed;
      }
    },
  },
  mounted() {
    this.getCryptoAssets()
  },
  computed: {
     ...mapGetters(["user","cryptoAssets","userBots","cryptoAddress"]),
    ifTransparent() {
      return this.$store.state.isTransparent;
    },
    deposited(){
      if (this.user?.deposits)
        return this.user?.deposits[this.robot.slug] 
      else return 0
    },
    minValue(){
      var deposited = this.deposited
      if (deposited === null) {deposited = 0}
      var limit = this.robot.minimum   - deposited

      if (this.network == 'trc20' && limit/90<15)  return 15
      else {
        if (limit<0)
          return 5
        else
          return limit/90
      }
    },
    networks() {
      var result = []
      
      this.cryptoAssets.forEach(item => {

        if (item.slug === this.asset){
          console.log('slugs',item.slug,item)
          result = item.networks
        }
      })
      
      return result
    },

  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-dark";
    // Deactivate sidenav type buttons on resize and small screens
    this.sidebarColor('warning')
  },
};
</script>


<style scoped>
.lav-replenishment__period_item-soon{
  position: absolute;
  right: -10px;
  top:-10px;
  background-color: red;
  padding: 2px 5px;
  border-radius: 5px;
  color: #fff;
}
.lav-replenishment__period_item-union{
  position: relative;
  align-items: center;
  background: #2E2E2E;
  height: 70px;
  border-radius: 12px;
  border: 1px solid rgb(255 255 255 / 17%);
  cursor: not-allowed;
  text-align: center;
}

.lav-replenishment__period_item-name-union{
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 15px;
  width: 70%;
  text-align: center;
  color: #FFFFFF;
}

.lav-replenishment__period_item-sum-union {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  color: #FFFFFF;
}

</style>



<style scoped>
.alex-qr-code{
   background-color: #fff;
   width: 80%;
   border-radius: 40px;
}
.alex-qr-code_btn-copy{
  border: 1px solid #fff;
  border-radius: 30px;
  width:fit-content;
  padding: 2px 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
.alex-qr-code_btn-copy:hover{
  background-color: #3a3a3a;

}


/* @media (prefers-reduced-motion: no-preference) {
  html {
     scroll-behavior: smooth;
  }
} */

.lav-desktop {
  display: flex !important;
}

.lav-mobile {
  display: none !important;
}

body {
  background: #0C0C0C;
  font-family: "Inter", sans-serif;
  color: #fff;
  overflow-x: hidden;
  height: auto;
}

/* lav-replenishment */
.lav-replenishment {
  max-width: 390px;
  width: 100%;
  padding: 80px 0px 0px 0px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lav-replenishment-tile {
  font-family: "Inter", sans-serif;
  font-size: 34px;
  line-height: 35px;
  color: #FFFFFF;
  align-self: flex-start;
}

.lav-replenishment-subtitle {
  font-family: "Inter", sans-serif;
  margin-top: 16px;
  font-size: 16px;
  line-height: 21px;
  color: #E4E4E4;
}

.lav-replenishment__period {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 11px 0;
}
.height50px{
  height: 50px;
}

.lav-replenishment__period_item {
  display: flex;
  
  flex-direction: column;
  align-items: center;
  background: #2E2E2E;
  border-radius: 12px;
  border: 1px solid rgb(255 255 255 / 17%);
  cursor: pointer;
}

.lav-replenishment-select {
  border: 1px solid #FAB23C !important;
  /* background: radial-gradient(50% 54.42% at 50% 50%, #557AD3 0%, #05379B 100%) !important; */
}

.lav-replenishment__period_item-name {
  font-family: "Inter", sans-serif;
  padding: 23px 0 18px;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #FFFFFF;
  width: 100%;
  border-bottom: 1px solid rgb(255 255 255 / 17%);
}

.lav-replenishment__period_item-sum {
  padding: 48px 0 30px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #FFFFFF;
}

.lav-sum-replenishment {
  margin-top: 41px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.lav-sum-replenishment-title {
  font-family: "Inter", sans-serif;
  font-size: 19px;
  line-height: 35px;
  color: #FFFFFF;
}

.lav-sum-replenishment-input {
  margin-top: 22px;
  background: #2e2e2e;
  border-radius: 16px;
  padding: 18px 22px;
  font-family: "Inter", sans-serif;
  font-size: 22px;
  line-height: 34px;
  color: #b3b3b3;
  border:0px;
}

.lav-sum-replenishment-input::-webkit-outer-spin-button,
.lav-sum-replenishment-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.lav-sum-replenishment-input,
.lav-sum-replenishment-input:hover,
.lav-sum-replenishment-input:focus {
  appearance: none;
  -moz-appearance: textfield;
  border-color:#000000 !important;
  box-shadow:0 0 0 0px #ffffff !important;
}

.lav-sum-replenishment_choice {
  margin-top: 19px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.lav-sum-replenishment_choice-item {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 10px 9px;
  background: #1B1B1B;
  border-radius: 90px;
  cursor: pointer;
  border: 1px solid rgb(255 255 255 / 17%);
  font-family: "Inter", sans-serif;
  font-size: 18px;
  color: #F2F2F2;
}

.lav-replenishment-promocode-btn {
  margin: 80px 0;
  font-family: "Inter", sans-serif;
  font-size: 22px;
  line-height: 35px;
  text-align: center;
  color: #FFFFFF;
  position: relative;
  padding:15px 20px 10px 20px;
  border-radius: 10px;
  
  background: radial-gradient(50% 134.49% at 50% 50%, #557AD3 0%, #05379B 100%);
}

.lav-replenishment-promocode-btn::before {
  content: '';
  position: absolute;
  width: 249px;
  height: 249px;

  top: 50%;
  left: 50%;
  z-index: -1;
  transform: translate3d(-50%, -50%, 0);


  background: radial-gradient(50% 50% at 50% 50%, #FFB800 0%, #483400 100%);
  filter: blur(26.35px);
  border-radius: 50%;
}

.lav-replenishment-btn {
  width: 100%;
  padding: 20px 0 19px;
  background: radial-gradient(50% 134.49% at 50% 50%, #557AD3 0%, #05379B 100%);
  border-radius: 16px;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #FFFFFF;
}

.lav-crypt .lav-sum-replenishment_choice {
  gap: 10px 5px;
}
.lav-crypt .lav-sum-replenishment_choice-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 19px 15px 15px;
  background: #1B1B1B;
  border-radius: 15px;
  font-family: "Inter", sans-serif;
}

</style>
