<template>
  
  <div>
    <section class="lav-affiliateProgram">
       <div class="lav-container">
          <header class="lav-affiliateProgram-header">
             <h1 class="lav-affiliateProgram-title">{{$t('tasks.partner_program')}}</h1>
             <p class="lav-affiliateProgram-text">{{$t('tasks.desc_p')}}
                   <br>
                   <br>
                   <a  target="_blank" href="https://docs.google.com/presentation/d/1KLzGLulrjROgjrKJFEKfSpAuPzj3NYDf6nWC3qMEcGM/pub?start=false&loop=false&delayms=3000#slide=id.p" style="padding:10px; border-radius:10px;font-size:18px; font-weight:800" class=" lav-btn-web">{{$t('tasks.read_det')}} </a> 
             </p>
          </header>
          <!-- <div class="lav-affiliateProgram-figure">
             <div @click="copya(ref_link)"  class="lav-btn-invite">{{$t('tasks.invite')}}</div>
             <svg width="1512" height="1635" viewBox="0 0 1512 1635" fill="none" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <circle cx="766.5" cy="853.5" r="248.75" stroke="url(#paint1_linear_241_13488)" stroke-opacity="0.64"
                   stroke-width="1.5" />
                <path
                   d="M1086.25 852.5C1086.25 1030.2 942.42 1174.25 765 1174.25C587.58 1174.25 443.75 1030.2 443.75 852.5C443.75 674.801 587.58 530.75 765 530.75C942.42 530.75 1086.25 674.801 1086.25 852.5Z"
                   stroke="white" stroke-opacity="0.66" stroke-width="1.5" />
                <path
                   d="M1159.25 852.5C1159.25 1069.96 982.739 1246.25 765 1246.25C547.261 1246.25 370.75 1069.96 370.75 852.5C370.75 635.039 547.261 458.75 765 458.75C982.739 458.75 1159.25 635.039 1159.25 852.5Z"
                   stroke="white" stroke-opacity="0.2" stroke-width="1.5" />
                <circle cx="765" cy="853" r="539.25" stroke="white" stroke-opacity="0.1" stroke-width="1.5" />
                <g clip-path="url(#clip0_241_13488)">
                   <rect x="463" y="1054" width="161" height="160" rx="80" fill="white" />
                   <rect x="463" y="1015" width="165" height="240" fill="url(#pattern0_241_13488)"></rect>
                </g>
                <g clip-path="url(#clip1_241_13488)">
                   <rect x="737" y="573" width="107" height="107" rx="53.5" fill="white" />
                   <rect x="737" y="559" width="107" height="140" fill="url(#pattern1_241_13488)"></rect>
                </g>
                <g clip-path="url(#clip2_241_13488)">
                   <rect x="937" y="491" width="79" height="78" rx="39" fill="white" />
                   <rect x="937" y="481" width="80" height="101" fill="url(#pattern2_241_13488)"></rect>
                </g>
                <g clip-path="url(#clip3_241_13488)">
                   <rect x="969" y="1214" width="173" height="174" rx="86.5" fill="white" />
                   <rect x="969" y="1183" width="181" height="242" fill="url(#pattern3_241_13488)"></rect>
                </g>
                <g clip-path="url(#clip4_241_13488)">
                   <rect x="404" y="355" width="135" height="136" rx="67.5" fill="white"></rect>
                   <rect x="404" y="326" width="135" height="191" fill="url(#pattern4_241_13488)"></rect>
                </g>
                <g filter="url(#filter0_dd_241_13488)">
                   <rect x="421" y="648" width="103" height="97" rx="48.5" fill="black" />
                   <path
                      d="M465.731 708.669V689.619C465.731 688.869 465.956 688.138 466.369 687.519L471.487 679.906C472.294 678.688 474.3 677.825 476.006 678.463C477.844 679.081 479.062 681.144 478.669 682.981L477.694 689.113C477.619 689.675 477.769 690.181 478.087 690.575C478.406 690.931 478.875 691.156 479.381 691.156H487.087C488.569 691.156 489.844 691.756 490.594 692.806C491.306 693.819 491.437 695.131 490.969 696.463L486.356 710.506C485.775 712.831 483.244 714.725 480.731 714.725H473.419C472.162 714.725 470.4 714.294 469.594 713.488L467.194 711.631C466.275 710.938 465.731 709.831 465.731 708.669Z"
                      fill="white" />
                   <path
                      d="M459.769 685.962H457.837C454.931 685.962 453.75 687.087 453.75 689.862V708.725C453.75 711.5 454.931 712.625 457.837 712.625H459.769C462.675 712.625 463.856 711.5 463.856 708.725V689.862C463.856 687.087 462.675 685.962 459.769 685.962Z"
                      fill="white" />
                </g>
                <g filter="url(#filter1_dd_241_13488)">
                   <rect x="747" y="1198" width="102" height="97" rx="48.5" fill="black" />
                   <path
                      d="M808.741 1229.8L808.593 1237.85C808.571 1238.96 809.273 1240.42 810.165 1241.08L815.435 1245.08C818.814 1247.63 818.261 1250.75 814.224 1252.03L807.36 1254.17C806.213 1254.53 805.001 1255.79 804.704 1256.96L803.068 1263.2C801.771 1268.13 798.541 1268.62 795.864 1264.29L792.124 1258.23C791.444 1257.13 789.829 1256.3 788.554 1256.36L781.456 1256.72C776.378 1256.98 774.933 1254.04 778.248 1250.18L782.455 1245.29C783.241 1244.38 783.603 1242.68 783.241 1241.53L781.074 1234.64C779.82 1230.61 782.073 1228.37 786.089 1229.69L792.358 1231.75C793.42 1232.09 795.014 1231.86 795.906 1231.2L802.451 1226.48C806 1223.95 808.826 1225.44 808.741 1229.8Z"
                      fill="white" />
                   <path
                      d="M817.56 1264.5L811.121 1258.06C810.505 1257.44 809.485 1257.44 808.869 1258.06C808.252 1258.68 808.252 1259.7 808.869 1260.31L815.307 1266.75C815.626 1267.07 816.03 1267.22 816.434 1267.22C816.837 1267.22 817.241 1267.07 817.56 1266.75C818.176 1266.13 818.176 1265.11 817.56 1264.5Z"
                      fill="#292D32" />
                </g>
                <g filter="url(#filter2_dd_241_13488)">
                   <rect x="1044" y="758" width="107" height="95" rx="47.5" fill="black" />
                   <path
                      d="M1097.5 816.423C1099.34 816.423 1100.83 814.933 1100.83 813.095C1100.83 811.257 1099.34 809.767 1097.5 809.767C1095.66 809.767 1094.17 811.257 1094.17 813.095C1094.17 814.933 1095.66 816.423 1097.5 816.423Z"
                      fill="white" />
                   <path
                      d="M1110.32 800.457V797.905C1110.32 792.392 1108.99 785.083 1097.5 785.083C1086.01 785.083 1084.68 792.392 1084.68 797.905V800.457C1078.96 801.172 1077.08 804.071 1077.08 811.196V814.994C1077.08 823.365 1079.64 825.917 1088.01 825.917H1106.99C1115.36 825.917 1117.92 823.365 1117.92 814.994V811.196C1117.92 804.071 1116.04 801.172 1110.32 800.457ZM1097.5 819.261C1094.09 819.261 1091.33 816.484 1091.33 813.095C1091.33 809.685 1094.11 806.929 1097.5 806.929C1100.89 806.929 1103.67 809.706 1103.67 813.095C1103.67 816.505 1100.91 819.261 1097.5 819.261ZM1088.01 800.273C1087.84 800.273 1087.7 800.273 1087.54 800.273V797.905C1087.54 791.923 1089.23 787.942 1097.5 787.942C1105.77 787.942 1107.46 791.923 1107.46 797.905V800.294C1107.3 800.294 1107.16 800.294 1106.99 800.294H1088.01V800.273Z"
                      fill="white" />
                </g>
                <path d="M1282 1174.38C1199.73 1195.87 1000.96 1195.28 864 1021" stroke="url(#paint2_linear_241_13488)"
                   stroke-width="13" stroke-linecap="round" />
                <path
                   d="M801.251 945.295C801.028 942.224 804.213 940.063 806.984 941.405L875.259 974.477C878.383 975.991 878.229 980.493 875.008 981.788L843.603 994.419C841.784 995.151 840.139 996.256 838.775 997.664L813.62 1023.61C811.216 1026.09 807.009 1024.56 806.759 1021.12L801.251 945.295Z"
                   fill="black" stroke="white" stroke-width="4" />
                <defs>
                   <pattern id="pattern0_241_13488" patternContentUnits="objectBoundingBox" width="1" height="1">
                      <use xlink:href="#image0_241_13488" transform="matrix(0.00196563 0 0 0.00130719 -0.00123457 0)" />
                   </pattern>
                   <pattern id="pattern1_241_13488" patternContentUnits="objectBoundingBox" width="1" height="1">
                      <use xlink:href="#image1_241_13488" transform="matrix(0.00178219 0 0 0.00132979 -0.00257732 0)" />
                   </pattern>
                   <pattern id="pattern2_241_13488" patternContentUnits="objectBoundingBox" width="1" height="1">
                      <use xlink:href="#image2_241_13488" transform="matrix(0.0017762 0 0 0.00144727 0 -0.00292744)" />
                   </pattern>
                   <pattern id="pattern3_241_13488" patternContentUnits="objectBoundingBox" width="1" height="1">
                      <use xlink:href="#image3_241_13488" transform="scale(0.00177343 0.00136986)" />
                   </pattern>
                   <pattern id="pattern4_241_13488" patternContentUnits="objectBoundingBox" width="1" height="1">
                      <use xlink:href="#image4_241_13488" transform="matrix(0.00241546 0 0 0.00182478 0 -0.0045514)" />
                   </pattern>
                   <filter id="filter0_dd_241_13488" x="390.4" y="621.4" width="164.2" height="158.2"
                      filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="15.3" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix"
                         values="0 0 0 0 0.528331 0 0 0 0 0.672317 0 0 0 0 0.928988 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_241_13488" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha" />
                      <feOffset dy="1" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.53 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_241_13488" result="effect2_dropShadow_241_13488" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_241_13488" result="shape" />
                   </filter>
                   <filter id="filter1_dd_241_13488" x="716.4" y="1171.4" width="163.2" height="158.2"
                      filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="15.3" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix"
                         values="0 0 0 0 0.528331 0 0 0 0 0.672317 0 0 0 0 0.928988 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_241_13488" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha" />
                      <feOffset dy="1" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.53 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_241_13488" result="effect2_dropShadow_241_13488" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_241_13488" result="shape" />
                   </filter>
                   <filter id="filter2_dd_241_13488" x="1013.4" y="731.4" width="168.2" height="156.2"
                      filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="15.3" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix"
                         values="0 0 0 0 0.528331 0 0 0 0 0.672317 0 0 0 0 0.928988 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_241_13488" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha" />
                      <feOffset dy="1" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.53 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_241_13488" result="effect2_dropShadow_241_13488" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_241_13488" result="shape" />
                   </filter>
                   <filter id="filter3_d_241_13488" x="583.3" y="774.3" width="383.4" height="147.4"
                      filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha" />
                      <feOffset dy="-1" />
                      <feGaussianBlur stdDeviation="7.85" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix"
                         values="0 0 0 0 0.528331 0 0 0 0 0.672317 0 0 0 0 0.928988 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_241_13488" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_241_13488" result="shape" />
                   </filter>
                   <linearGradient id="paint0_linear_241_13488" x1="756" y1="0" x2="756" y2="1825.64"
                      gradientUnits="userSpaceOnUse">
                      <stop offset="0.103808" stop-color="white" />
                      <stop offset="0.518978" stop-color="#0544B4" />
                      <stop offset="0.642146" stop-color="#062966" />
                      <stop offset="0.874862" stop-color="#06040F" />
                   </linearGradient>
                   <linearGradient id="paint1_linear_241_13488" x1="766.5" y1="604" x2="1071.69" y2="1000.53"
                      gradientUnits="userSpaceOnUse">
                      <stop stop-color="white" />
                      <stop offset="1" stop-color="white" stop-opacity="0" />
                   </linearGradient>
                   <linearGradient id="paint2_linear_241_13488" x1="1239.76" y1="1103.25" x2="838.443" y2="995.392"
                      gradientUnits="userSpaceOnUse">
                      <stop stop-color="#0642AE" stop-opacity="0.32" />
                      <stop offset="1" stop-color="#8EABDD" />
                   </linearGradient>
                   <radialGradient id="paint3_radial_241_13488" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(557 1542) scale(203.5 166.771)">
                      <stop stop-color="#557AD3" />
                      <stop offset="1" stop-color="#05379B" />
                   </radialGradient>
                   <radialGradient id="paint4_radial_241_13488" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(984 1542) scale(418.5 371.504)">
                      <stop stop-color="white" />
                      <stop offset="1" stop-color="#1A93CD" />
                   </radialGradient>
                   <clipPath id="clip0_241_13488">
                      <rect x="463" y="1054" width="161" height="160" rx="80" fill="white" />
                   </clipPath>
                   <clipPath id="clip1_241_13488">
                      <rect x="737" y="573" width="107" height="107" rx="53.5" fill="white" />
                   </clipPath>
                   <clipPath id="clip2_241_13488">
                      <rect x="937" y="491" width="79" height="78" rx="39" fill="white" />
                   </clipPath>
                   <clipPath id="clip3_241_13488">
                      <rect x="969" y="1214" width="173" height="174" rx="86.5" fill="white" />
                   </clipPath>
                   <clipPath id="clip4_241_13488">
                      <rect x="404" y="355" width="135" height="136" rx="67.5" fill="white" />
                   </clipPath>
                   <image id="image0_241_13488" width="510" height="765" xlink:href="../assets/img/image-4.jpg"></image>
                   <image id="image1_241_13488" width="564" height="752" xlink:href="../assets/img/image-3.jpg"></image>
                   <image id="image2_241_13488" width="563" height="695" xlink:href="../assets/img/image-2.jpg"></image>
                   <image id="image3_241_13488" width="564" height="730" xlink:href="../assets/img/image-5.jpg"></image>
                   <image id="image4_241_13488" width="414" height="553" xlink:href="../assets/img/image-1.jpg"></image>
                </defs>
             </svg>
 
          </div> -->
          <div class="lav-btn__wrapper">
             <a href="#" :class="[ !web_version ? 'lav-btn-telegram' : 'lav-btn-web',]" @click="web_version=true" >{{$t('tasks.link_for_web')}}</a>
             <a href="#" 
                :class="[ web_version ? 'lav-btn-telegram' : 'lav-btn-web',]"
              @click="web_version=false" >{{$t('tasks.link_for_tg')}}</a>
          </div>
       </div>
    </section>
 
    <section class="lav-statistics pb-10">
       <div class="lav-statistics-wrapper lav-container">
          <div class="lav-statistics__data">
             <div class="lav-statistics__data_item" v-if="web_version">
                <h3 class="lav-statistics__data_item-title">Qr-{{$t('tasks.code')}}</h3>
                <p class="lav-statistics__data_item-text">{{$t('tasks.show_individual')}}<br>
                   <span>QR-{{$t('tasks.code')}} {{$t('tasks.for_download')}}</span>
                </p>
                <div class="lav-qr"> <img :src="user.qr_address" style="width:100%; " alt=""></div>
             </div>


             <div class="lav-statistics__data_item"  v-if="web_version">
                <h3 class="lav-statistics__data_item-title"> {{$t('tasks.promo_code')}} </h3>
                <div class="lav-copy-block" @click="copya(user.secret_code)">
                   <div class="lav-promotional-code">{{user.secret_code}}</div>
                   <button class="lav-btn-copy">
                      <img src="../assets/img/copy.svg" alt="copy">
                   </button>
                </div>
             </div>

            

             <div class="lav-statistics__data_item" v-if="web_version">
                <h3 class="lav-statistics__data_item-title"> {{$t('tasks.link')}}</h3>
                <p class="lav-statistics__data_item-text">{{$t('tasks.desk_info_ref_link.0')}}
                  <span>{{$t('tasks.desk_info_ref_link.1')}}</span> {{$t('tasks.desk_info_ref_link.2')}}
                  </p>
                <div @click="copya(ref_link)" class="lav-copy-block">
                   <a href="#" class="lav-referral-link">{{ref_link}}</a>
                   <button class="lav-btn-copy">
                      <img src="../assets/img/copy.svg" alt="копирование">
                   </button>
                </div>
             </div>
             <div class="lav-statistics__data_item" v-if="!web_version">
              <h3 class="lav-statistics__data_item-title">{{$t('tasks.link_for_tg')}}</h3>
              <p class="lav-statistics__data_item-text">{{$t('tasks.desk_info_ref_link.0')}}<span>{{$t('tasks.desk_info_ref_link.1')}}</span> {{$t('tasks.desk_info_ref_link.2')}}</p>
              <div  @click="copya(`https://t.me/telosmarketbot?start=secret_${user?.secret_code}`)"  class="lav-copy-block">
                 <a href="#" class="lav-referral-link">https://t.me/telosmarketbot?start=secret_{{user?.secret_code}}</a>
                 <button class="lav-btn-copy">
                    <img src="../assets/img/copy.svg" alt="copy">
                 </button>
              </div>


           </div>

          <div class="lav-statistics__content_item" v-if="!web_version">
            <div class="lav-statistics__content_item-title"> {{$t('tasks.clicks_tg')}} </div>
            <div class="lav-statistics__content_item-data">
              {{user?.ref_data_short?.tg_open}}  <span>(+{{user?.ref_data_short?.tg_open}})</span></div>
         </div>

          </div>
          <div class="lav-statistics__content">
            
             <h2 class="lav-statistics__content-title">{{$t('tasks.stat')}}</h2>
             

             <div class="lav-statistics__content_row">
                
                <div class="lav-statistics__content_item">
                   <div class="lav-statistics__content_item-title">{{$t('tasks.total_users')}}</div>
                   <div class="lav-statistics__content_item-data">
                    {{user.refs}} {{$t('tasks.chelovek') }}

                   </div>
                </div>

                <div class="lav-statistics__content_item">
                  <div class="lav-statistics__content_item-title">{{$t('tasks.volume_team')}} </div>
                  <div class="lav-statistics__content_item-data">
                   {{user.ref_data_short?.all_people_deposit.toLocaleString('en')}} $

                  </div>
               </div>
              </div>

               <div class="lav-statistics__content_row">

                <div class="lav-statistics__content_item">
                  <div class="lav-statistics__content_item-title"> {{$t('tasks.profit_from_team')}}   </div>
                  <div class="lav-statistics__content_item-data">
                    {{user.refearn?.toLocaleString('en') }} $
                   </div>
               </div>

               <div class="lav-statistics__content_item">
                <div class="lav-statistics__content_item-title">
                  {{$t('tasks.profit_from_team_passive')}}   </div>
                <div class="lav-statistics__content_item-data">
                  {{user.ref_data_short?.passive_income?.toLocaleString('en')  }} $
                </div>  
             </div>
             </div>
             <div class="lav-statistics__content_row">
               
                <div class="lav-statistics__content_item">
                   <div class="lav-statistics__content_item-title">
                    {{ $t('tasks.upgrade_self_portfolio')  }}</div>
                   <div class="lav-statistics__content_item-data">
                    +{{user.ref_data_short?.booster}} % / {{$t('tasks.month')}}
                   </div>
                </div>
               
             </div>

             <div class="lav-statistics__content_row">
              <div class="lav-statistics__content_item lav-yel">
                 <div class="lav-statistics__content_item-title text-dark">{{$t('tasks.total_m')}}</div>
                 <div class="lav-statistics__content_item-data  text-dark">
                  {{ (user.ref_data_short?.passive_income+user.refearn).toLocaleString('en') }} $
                 </div>
              </div>
            
           </div>

          </div>
 
          <div class="lav-separator lav-desktop"></div>
 
          <div v-if="user.refs>0" class="lav-statistics__btns">
             <button   @click="GoToStat()" class="lav-btn-history">{{$t('tasks.see_statis') }}</button>
          </div>
 
       </div>
    </section>
  </div>

<!--   
  <div class="mb-5 p-3 pt-6" style="max-width: 1000px;">
    <div class="balance-ref mb-5 pb-4 "  v-if="user.refs>0">
      
      <div class="row pt-3  text-center align-items-center justify-content-center"> 
        <div class="balance-available-value pt-1 pb-1" >реферальный счет</div>
      </div>
      <div class="row pt-3 text-balance-main text-center align-items-center justify-content-center"> 
          {{ user.ref_available.toLocaleString('en') }} {{$t('tasks.valuta') }}
      </div>
      <div class="row ">
        <div class=" mt-2   ps-5 pe-5 balance-available justify-content-center  "  style="text-align: center;">
          <span class=" pb-1" style="opacity: 0.8 !important;"> реферальный счет обновляется ежедневно, </span>
        </div>
      </div>
      
    </div>
    
    

    <div class="alex-office__info">
      <div class="alex-office__info-title">{{$t('tasks.partner') }}</div>

      

      <div class="alex-office__info-description " >
        {{$t('tasks.esli_vi') }} <strong> {{$t('tasks.povish_cashback') }} </strong>, {{$t('tasks.no_i') }} <strong>{{$t('tasks.procent') }}</strong>  {{$t('tasks.withcashback') }} <strong>{{$t('tasks.friend_and_friend') }}</strong>  
      </div>
      <div class="row mt-2 mb-2">
        <div class="select-source mb-2" @click="web_version=true"  :class="[ web_version ? 'selected' : '',]" >Для веб-версии</div>
        <div  class="select-source mb-2" @click="web_version=false"  :class="[ web_version ? '' : 'selected',]" >Внутри телеграмма</div>
      </div>

      
      <template v-if="web_version">
        <div class="alex-office__info_item w-100 mt-3 position-relative" style="background-color: #242121;">
        
          <div class="alex-office__info_item-text text-light" style="font-weight: 600;font-size: 18px;text-transform: uppercase;">{{$t('tasks.promocode') }}</div>
          <div class="alex-office__info_item-text text-light " style="font-weight: 600;font-size: 18px;text-transform: uppercase;">
           <div class=" end-5 position-absolute" @click="copy_secret_code()"   > <span class="me-2" ref="secretcode">{{user.secret_code}}</span>
 
               <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M16.5 13.4V17.6C16.5 21.1 15.1 22.5 11.6 22.5H7.4C3.9 22.5 2.5 21.1 2.5 17.6V13.4C2.5 9.9 3.9 8.5 7.4 8.5H11.6C15.1 8.5 16.5 9.9 16.5 13.4Z" stroke="#D4E0FD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
               <path d="M22.5 7.4V11.6C22.5 15.1 21.1 16.5 17.6 16.5H16.5V13.4C16.5 9.9 15.1 8.5 11.6 8.5H8.5V7.4C8.5 3.9 9.9 2.5 13.4 2.5H17.6C21.1 2.5 22.5 3.9 22.5 7.4Z" stroke="#D4E0FD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
               </svg>
           </div>  
         </div>
       </div>

        <div class="alex-office__info_item" >
          <div class="alex-office__info_item-img">
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.5 22C18.0228 22 22.5 17.5228 22.5 12C22.5 6.47715 18.0228 2 12.5 2C6.97715 2 2.5 6.47715 2.5 12C2.5 17.5228 6.97715 22 12.5 22Z" stroke="#363636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.5 8.5V14.5" stroke="#363636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M9.5 12.5L12.5 15.5L15.5 12.5" stroke="#363636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
          </div>
          <div class="alex-office__info_item-text">{{$t('tasks.qrcode') }}</div>

          
        </div>
        <div  style="width:100%; " class="d-flex justify-content-center"> <img :src="user.qr_address" style="width:100%; max-width:300px" alt=""></div>
      </template>

      <div class="alex-office__info_item">
        
         <div class="alex-office__info_item-text">
          <template v-if="web_version">
            Или воспользуйтесь реферальной ссылкой по которой пользователь сможет подключиться к проекту
          </template>
          <template v-else>
           Реферальная ссылка по которой пользователь сможет подключиться к проекту внутри телеграмма
          </template>

         </div>
      </div>
      <div  v-if="web_version" class="alex-office__info_item " style="background-color: #242121;" @click="copya(`${ref_link}`)">

         <div class="alex-office__info_item-img" >
          <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" width="34" height="34" rx="9" fill="white"/>
            <path d="M21.5 17.9V22.1C21.5 25.6 20.1 27 16.6 27H12.4C8.9 27 7.5 25.6 7.5 22.1V17.9C7.5 14.4 8.9 13 12.4 13H16.6C20.1 13 21.5 14.4 21.5 17.9Z" stroke="#242121" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M27.5 11.9V16.1C27.5 19.6 26.1 21 22.6 21H21.5V17.9C21.5 14.4 20.1 13 16.6 13H13.5V11.9C13.5 8.4 14.9 7 18.4 7H22.6C26.1 7 27.5 8.4 27.5 11.9Z" stroke="#242121" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            
         </div>
         <div class="alex-office__info_item-text  text-light" style="font-weight: 600; align-content: center;" ref="reflink">{{ ref_link }}</div>
      </div>

      <div  v-else class="alex-office__info_item " style="background-color: #242121;" @click="copya(`https://t.me/telosmarketbot?start=secret_${user?.secret_code}`)">
        <div class="alex-office__info_item-img" >
         <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
           <rect x="0.5" width="34" height="34" rx="9" fill="white"/>
           <path d="M21.5 17.9V22.1C21.5 25.6 20.1 27 16.6 27H12.4C8.9 27 7.5 25.6 7.5 22.1V17.9C7.5 14.4 8.9 13 12.4 13H16.6C20.1 13 21.5 14.4 21.5 17.9Z" stroke="#242121" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
           <path d="M27.5 11.9V16.1C27.5 19.6 26.1 21 22.6 21H21.5V17.9C21.5 14.4 20.1 13 16.6 13H13.5V11.9C13.5 8.4 14.9 7 18.4 7H22.6C26.1 7 27.5 8.4 27.5 11.9Z" stroke="#242121" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
           </svg>
           
        </div>
        
        <div class="alex-office__info_item-text  text-light" style="font-weight: 600; align-content: center;" ref="reflink">https://t.me/telosmarketbot?start=secret_{{user?.secret_code}}</div>
      
     </div>

     <div v-if="!web_version" class="row pb-3 mt-3"> 
      <div class="col">Переходы по ссылке в TG</div>
      <div class="col-5">{{user?.ref_data_short?.tg_open}} {{$t('tasks.chelovek') }} (<span style="color: green;">+{{user?.ref_data_short?.tg_open}}</span>)</div>
    </div>

      <div class="alex-office__info_item">
        
        <div class="alex-office__info_item-text">
          {{$t('tasks.registration') }}
          
        </div>
     </div>
   </div> 


   <div class="row mt-5">
      <span class="svodka">Статистика</span>
    </div>
    <div class="row mt-5  ">
      
      

      <div class="row pb-3 border-bottom"> 
        <div class="col">Кол-во пользователей в команде</div>
        <div class="col-5">{{user.refs}} {{$t('tasks.chelovek') }}</div>
      </div>
      <div class="row mt-2 pb-3  border-bottom"> 
        <div class="col">
          Дополнительный бонус к личному портфелю</div>
        <div class="col-5">+0% / в месяц</div>
      </div>

      <div class="row mt-2 pb-3  border-bottom"> 
        <div class="col">
          Моя прибыль от прироста объема команды
        </div>
        <div class="col-5"> {{user.refearn?.toLocaleString('en') }} $</div>
      </div>
      <div class="row mt-2 pb-3  "> 
        <div class="col">Пассивный доход с дохода команды </div>
        <div class="col-5"> {{user.ref_data_short?.passive_income?.toLocaleString('en')  }} $</div>
      </div>
     
      <div class="row border-bottom mt-5"></div>
      <div class="row  mt-2"> 
        <div class="col">Итого</div>
        <div class="col-5">{{ (user.ref_data_short?.passive_income+user.refearn).toLocaleString('en') }} $</div>
      </div>


      <div class="alex-btn-box mt-5" @click="GoToStat()" v-if="user.refs>0">
        <div class="alex-btn-see-results">{{$t('tasks.see_statis') }}</div>
     </div>

     <div class="alex-btn-box mt-5 " v-else>
      <div class="alex-btn-see-results p-2">{{$t('tasks.stat_pozhe') }}</div>
   </div>
    </div>
  
   <div class="row mt-5 ">
    <span class="svodka">{{$t('tasks.how_work') }}</span>

      <hr class="m-2">
      <img style="max-width:50%; margin-left:-34px;" src="https://backend.l1st.online/media/img/ref.jpg" alt="">
      <a target="_blank" href="https://telegra.ph/Partnerskaya-programma-04-21-2" type="button" class="mt-2 btn btn-lg btn-dark"> Читать подробнее </a>
    </div>
  <div>
 
  </div>
</div> -->
</template>


<style scoped>


.select-source{
  width:180px;
  height: 50px;
  border: 1px solid #c3c3c3;
  border-radius: 8px;
  text-align: center;
  align-content: center;
  margin-left:10px;
  cursor: pointer;

}
.select-source.selected{
  border: 3px solid #000;
  background-color: #ffffff;
}




/* @media (prefers-reduced-motion: no-preference) {
  html {
     scroll-behavior: smooth;
  }
} */

.lav-desktop {
  display: flex !important;
}

.lav-mobile {
  display: none !important;
}


.lav-container {
  max-width: 1512px;
  margin: 0 auto;
}

/* lav-affiliateProgram */

.lav-affiliateProgram {
  margin: 0 auto;
  padding-top: 120px;
  padding-bottom: 31px;
  background: linear-gradient(180deg, #FAB23C 1.59%, #FFAB02 60.95%,  #000000 100%);
}

.lav-affiliateProgram-header {
  padding: 0 213px 0 212px;
  display: flex;
  gap: 25px;
  color: #000;
  
}

.lav-affiliateProgram-title {
  width: 553px;
  font-family: "Inter", sans-serif;
  font-size: 70px;
  line-height: 74px;
}

.lav-affiliateProgram-text {
  width: 510px;
  font-family: "Inter", sans-serif;
  color: #181919;
  font-size: 28px;
  line-height: 34.4px;
}

.lav-affiliateProgram-figure {
  position: relative;
  margin-top: 45px;
  width: 100%;
  height: 1150px;
  display: flex;
  overflow: hidden;
  align-items: center;
}

.lav-btn-invite {
  position: absolute;
  width: 352px;
  height: 116px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 53%;
  left: 51%;
  transform: translate3d(-50%, -50%, 0);
  font-family: "Inter", sans-serif;
  font-size: 32px;
  line-height: 35px;
  color: #FFFFFF;
  background-color: #000;
  background: #000000;
  box-shadow: 0px -1px 15.7px #ffffff;
  border-radius: 10px;
  cursor: pointer;
  z-index: 1;
}

.lav-btn__wrapper {
  margin-top: 87px;
  display: flex;
  gap: 20px;
  justify-content: center;
}

.lav-btn__wrapper a {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  border-radius: 15px;
  font-family: "Inter", sans-serif;
  cursor: pointer;
  height: 84px;
}

.lav-btn-web {
  padding: 45px 63px;
  color: #fff;
  background: #000;
  border:2px solid #fff;
}
.lav-btn-telegram {
  padding: 45px 42px;
  color: #000000;
  background: radial-gradient(102.83% 299.6% at 50% 50%, #FFFFFF 0%, #FFFFFF 100%);
}

/* lav-affiliateProgram END */

/* lav-statistics */
.lav-statistics {
  background-color: #000;
}

.lav-statistics-wrapper {
  padding: 100px 144px 112px 103px;
  display: flex;
  flex-direction: column;
}

.lav-statistics__data {
  display: flex;
  gap: 109px;
}

.lav-statistics__data_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 28%;
}

.lav-statistics__data_item-title {
  font-family: "Inter", sans-serif;
  font-weight: 800;
  font-size: 38px;
  line-height: 35px;
  color: #FFFFFF;
}

.lav-statistics__data_item-text {
  margin-top: 25px;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #E4E4E4;
}

.lav-statistics__data_item-text span {
  font-family: "Inter", sans-serif;
}

.lav-qr {
  width: 264px;
  height: 264px;
  background: #FFFFFF;
  border-radius: 10px;
  margin-top: 50px;
}

.lav-copy-block {
  margin-top: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 38px 20px;
  background: #15171B;
  border-radius: 13px;
}

.lav-btn-copy {
  min-width: 56px;
  height: 56px;
  background: #000000;
  box-shadow: 0px 1px 4px rgba(255, 255, 255, 0.53), 0px 4px 30.6px #ede687;
  border-radius: 90px;
  margin-left: 20px;
}

.lav-promotional-code {
  font-style: italic;
  font-family: "Inter", sans-serif;
  font-size: 38px;
  line-height: 35px;
  text-align: center;
  color: #FFFFFF;
  flex: 1;
}

.lav-referral-link {
  font-family: "Inter", sans-serif;
  font-style: italic;
  font-size: 12px;
  line-height: 19px;
  color: #FFFFFF;
  word-break: break-all;
  overflow-wrap: anywhere;
}

.lav-statistics__content {
  margin-top: 250px;
  display: flex;
  flex-direction: column;
}

.lav-statistics__content-title {
  font-family: "Inter", sans-serif;
  font-size: 150px;
  line-height: 74px;
  color: #FFFFFF;
  margin-bottom: 96px;
}

.lav-statistics__content_row {
  width: 100%;
  display: flex;
  gap: 17px;
  margin-bottom: 40px;
}

.lav-statistics__content_item {
  width: 348px;
  height: 172px;
  display: flex;
  flex-direction: column;
  border: 1px solid #FFFFFF;
  border-radius: 30px;
}

.lav-yel {
  background: linear-gradient(180deg, #FAB23C 11.59%, #FAB23C 50.95%);
;
}

.lav-statistics__content_item-title {
  height: 75px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #E4E4E4;
  border-bottom: 1px solid #fff;
}

.lav-statistics__content_item-data {
  font-family: "Inter", sans-serif;
  font-size: 19px;
  line-height: 21px;
  color: #E4E4E4;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.lav-statistics__content_item-data span {
  color: #F4D380;
  margin-left: 10px;
}

.lav-separator {
  width: 100%;
  height: 2px;
  background-color: #fff;
  margin-top: 98px;
  margin-bottom: 108px;
}

.lav-statistics__btns {
  display: flex;
  gap: 31px;
}

.lav-btn-history {
  width: 348px;
  padding: 23px 0;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  line-height: 35px;
  text-align: center;
  color: #000;
  background:#FAB23C;
  border-radius: 11px;
}

.lav-btn-details {
  width: 348px;
  padding: 23px 0;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  line-height: 35px;
  text-align: center;
  color: #FFFFFF;
  background: #222222;
  border-radius: 11px;
}

/* lav-statistics END */

@media screen and (width > 768px) and (width < 1520px) {
  .lav-affiliateProgram {
     padding-top: 7.93vw;
     padding-bottom: 2.05vw;
  }

  .lav-affiliateProgram-header {
     padding: 0 14.07vw 0 14.01vw;
     gap: 1.65vw;
  }

  .lav-affiliateProgram-title {
     width: 36.54vw;
     font-size: 4.63vw;
     line-height: 4.89vw;
  }

  .lav-affiliateProgram-text {
     width: 33.7vw;
     font-size: 1.85vw;
     line-height: 2.27vw;
  }

  .lav-affiliateProgram-figure {
     margin-top: 2.97vw;
     height: 75.98vw;
  }

  .lav-btn-invite {
     width: 23.26vw;
     height: 7.66vw;
     font-size: 2.11vw;
     line-height: 2.31vw;
     box-shadow: vw -.07vw 1.04vw #e3ed87;
     border-radius: .66vw;
  }

  .lav-btn__wrapper {
     margin-top: 5.75vw;
     gap: 1.32vw;
  }

  .lav-btn__wrapper a {
     font-size: 2.11vw;
     line-height: 2.31vw;
     border-radius: 1.32vw;
  }

  .lav-btn-web {
     padding: 2.97vw 4.16vw;
  }

  .lav-btn-telegram {
     padding: 2.97vw 2.78vw;
  }

  /* lav-affiliateProgram END */

  /* lav-statistics */
  .lav-statistics-wrapper {
     padding: 6.61vw 9.51vw 7.4vw 6.81vw;
  }

  .lav-statistics__data {
     gap: 7.2vw;
  }

  .lav-statistics__data_item-title {
     font-size: 2.51vw;
     line-height: 2.31vw;
  }

  .lav-statistics__data_item-text {
     margin-top: 1.65vw;
     font-size: 1.06vw;
     line-height: 1.39vw;
  }

  .lav-qr {
     width: 17.44vw;
     height: 17.44vw;
     border-radius: .66vw;
     margin-top: 3.3vw;
  }

  .lav-copy-block {
     margin-top: 3.3vw;
     padding: 2.51vw 1.32vw;
     border-radius: .86vw;
  }

  .lav-btn-copy {
     min-width: 3.7vw;
     height: 3.7vw;
     box-shadow: vw .07vw .26vw rgba(255, 255, 255, 0.53), vw .26vw 2.02vw #e6ed87;
     border-radius: 5.95vw;
     margin-left: 1.32vw;
  }

  .lav-promotional-code {
     font-size: 2.51vw;
     line-height: 2.31vw;
  }

  .lav-referral-link {
     font-size: .79vw;
     line-height: 1.26vw;
  }

  .lav-statistics__content {
     margin-top: 16.52vw;
  }

  .lav-statistics__content-title {
     font-size: 9.91vw;
     line-height: 4.89vw;
     margin-bottom: 6.34vw;
  }

  .lav-statistics__content_row {
     gap: 1.12vw;
     margin-bottom: 2.64vw;
  }

  .lav-statistics__content_item {
     width: 22.99vw;
     height: 11.36vw;
     border: .07vw solid #FFFFFF;
     border-radius: 1.32vw;
  }

  .lav-statistics__content_item-title {
     height: 4.96vw;
     font-size: .93vw;
     line-height: 1.39vw;
     border-bottom: .07vw solid #fff;
  }

  .lav-statistics__content_item-data {
     font-size: 1.26vw;
     line-height: 1.39vw;
  }

  .lav-statistics__content_item-data span {
     margin-left: .66vw;
  }

  .lav-separator {
     height: .13vw;
     margin-top: 6.48vw;
     margin-bottom: 7.14vw;
  }

  .lav-statistics__btns {
     gap: 2.05vw;
  }

  .lav-btn-history {
     width: 22.99vw;
     padding: 1.52vw 0;
     font-size: 1.19vw;
     line-height: 2.31vw;
     border-radius: .73vw;
  }

  .lav-btn-details {
     width: 22.99vw;
     padding: 1.52vw 0;
     font-size: .99vw;
     line-height: 2.31vw;
     border-radius: .73vw;
  }

  /* lav-statistics END */
}

@media screen and (width < 768px) {

  .lav-desktop {
     display: none !important;
  }

  .lav-mobile {
     display: flex !important;
  }

  .lav-affiliateProgram {
     padding-top: 106px;
  }

  .lav-affiliateProgram-header {
     flex-direction: column;
     gap: 25px;
  }

  .lav-affiliateProgram-header {
     padding: 0 40px;
  }

  .lav-affiliateProgram-title,
  .lav-affiliateProgram-text {
     width: 100%;
     text-align: center;
  }

  .lav-affiliateProgram-title {
     font-size: 38px;
     line-height: 35px;
  }

  .lav-affiliateProgram-text {
     font-size: 16px;
     line-height: 21px;
  }

  .lav-affiliateProgram-figure {
     height: 135vw;
  }

  .lav-affiliateProgram-figure svg {
     scale: 1.8;
  }

  .lav-btn-invite {
     width: 158px;
     height: 52px;
     font-size: 13px;
  }

  .lav-btn__wrapper {
     margin-top: 81px;
     gap: 9px;
     padding: 0 20px;
  }

  .lav-btn__wrapper a {
     font-size: 16px;
     padding: 0;
     flex: 1;
     border-radius: 11px;
     height: 54px;
  }

  .lav-statistics-wrapper {
     padding: 75px 20px 57px;
  }

  .lav-statistics__data {
     flex-direction: column;
     gap: 122px;
  }

  .lav-statistics__data_item {
     width: 100%;
     position: relative;
  }

  .lav-statistics__data_item::before {
     content: '';
     position: absolute;
     height: 2px;
     width: 57px;
     bottom: -60px;
     background: #fff;
     z-index: 1;
  }

  .lav-statistics__data_item:last-child::before {
     display: none;
  }

  .lav-statistics__data_item:nth-child(2) {
     order: -1;
  }

  .lav-statistics__content {
     margin-top: 119px;
  }

  .lav-statistics__content-title {
     font-size: 38px;
     text-align: center;
     margin-bottom: 50px;
  }

  .lav-statistics__content_row {
     flex-direction: column;
     align-items: center;
     margin-bottom: 17px;
  }

  .lav-statistics__btns {
     margin-top: 107px;
     flex-direction: column;
     align-items: center;
  }
}

@media screen and (width < 390px) {
  .lav-btn__wrapper a {
     font-size: 4.15vw;
     line-height: 9.08vw;
     padding: 2.33vw 0;
     flex: 1;
     border-radius: 2.85vw;
  }

  .lav-copy-block {
     padding: 9.86vw 5.19vw;
     border-radius: 3.37vw;
  }

  .lav-promotional-code {
     font-size: 9.86vw;
     line-height: 9.08vw;
  }

  .lav-statistics__content_item,
  .lav-btn-history,
  .lav-btn-details {
     width: 100%;
  }

  .lav-btn-details {
     padding: 5.97vw 2.15vw;
     font-size: 3.6vw;
     line-height: 9.08vw;
     text-align: center;
     border-radius: 2.85vw;
  }
}
</style>
<script>
// import SoftSwitch from "@/components/SoftSwitch.vue";
// import Telegram from "./components/Telegram.vue";

// import ProfileInfoCard from "./components/ProfileInfoCard.vue";
import sophie from "@/assets/img/kal-visuals-square.jpg";
import marie from "@/assets/img/marie.jpg";
import tableRef from "@/assets/img/table-ref.png";
import tableRefExtend from "@/assets/img/table-ref-levels.png";
import ivana from "@/assets/img/ivana-square.jpg";
import peterson from "@/assets/img/team-4.jpg";
import nick from "@/assets/img/team-3.jpg";
import img1 from "@/assets/img/home-decor-1.jpg";
import img2 from "@/assets/img/home-decor-2.jpg";
import img3 from "@/assets/img/home-decor-3.jpg";
import team1 from "@/assets/img/team-1.jpg";
import team2 from "@/assets/img/team-2.jpg";
import team3 from "@/assets/img/team-3.jpg";
import team4 from "@/assets/img/team-4.jpg";
import referal from "@/assets/img/reg.jpeg";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
// import DefaultProjectCard from "./components/DefaultProjectCard.vue";
// import PlaceHolderCard from "@/examples/Cards/PlaceHolderCard.vue";
// import setNavPills from "@/assets/js/nav-pills.js";
// import setTooltip from "@/assets/js/tooltip.js";
import { mapGetters,mapActions } from "vuex";
import profile_photo from "@/assets/img/profile.webp";

export default {
  name: "ProfileOverview",
  components: {
    // SoftSwitch,
    // ProfileInfoCard,
    // Telegram
    // DefaultProjectCard,
    // PlaceHolderCard,
  },
  data() {
    return {
      showMenu: false,
      loading: false,
      sophie,
      tableRef,
      tableRefExtend,
      marie,
      ivana,
      peterson,
      nick,
      img1,
      team1,
      team2,
      team3,
      team4,
      img2,
      img3,
      faFacebook,
      faTwitter,
      faInstagram,
      photo: profile_photo,
      referal: referal,
      old_password: "",
      // ref_link:'1234',
      new_password_1: "",
      new_password_2: "",
      web_version: true,
    };
  },
  computed:{
    ...mapGetters(["user"]),
    
    ref_link(){
      return `https://tmarket.me/l/ref?uid=${this.user.uid}`
    },
    info (){
      return {
            fullName: this.user.first_name,
            mobile: this.user.phone,
            email: this.user.email,
          }
    }
  },
  methods: {
    ...mapActions(["getUserData","updateUser","logout","restore", "change_password"]),
    send_new_password(){
      var data = {'email':this.user.email, 'no_redirect': true}
      this.restore({vm:this,data:data})
    },
    GoToStat(){
      this.$router.push({path: '/inapp/statref'})
    },
    gotochat(){
      this.$router.push({path: '/inapp/gotochatfromtask'})
      setTimeout(() => {
        this.$router.push({query: {}})
      }, 1000);
    },
    copya(mytext) {
      try {
        navigator.clipboard.writeText(mytext);
        this.$toast.success(this.$t('statref.copy_buffer'), { });
      } catch($e) {
        this.$toast.error(this.$t('statref.no_copy'), { });
      }
    },

    copyreflink() {
      const storage = document.createElement('textarea');
      storage.value = this.$refs.reflink.innerHTML;
      this.$refs.reference.appendChild(storage);
      storage.select();
      storage.setSelectionRange(0, 99999);
      document.execCommand('copy');
      this.$refs.reference.removeChild(storage);
      this.$toast.success(this.$t('statref.copy_buffer'), { });
    },

    copy_secret_code() {
      const storage = document.createElement('textarea');
      storage.value = this.$refs.secretcode.innerHTML;
      this.$refs.reference.appendChild(storage);
      storage.select();
      storage.setSelectionRange(0, 99999);
      document.execCommand('copy');
      this.$refs.reference.removeChild(storage);
      this.$toast.success(this.$t('statref.copy_buffer'), { });
    },


    copy() {
      this.$refs.clone.focus();
      document.execCommand('copy');
      this.$toast.success('Скопировано!');
    },
    openInstruction(){
      alert('Инструкция находится на модерации, дата официальной публикации 15 ноября 2022')
    },  
    change_password_callback(){
      this.loading = true
      var data = {
        email:this.user.email, 
        old_password: this.old_password,
        new_password_1: this.new_password_1,
        new_password_2: this.new_password_2,
      }
      this.change_password({vm:this,data:data})
    },
    change(type,event){
      console.log('change',event.target.checked,event.target.name)

      if (type==='checkbox') {
        var data = {}
        data[event.target.name] = event.target.checked
        console.log(data)
         this.updateUser({vm:this,data:data})
      }
     
      // else if (type==='profile') this.updateUser({
      //   first_name: this.user.first_name,
      //   phone: this.user.phone,
      // })
      // else if (type==='photo') this.updateUser({
      //   photo: this.photo
      // })

    }
  },
  watch:{
    
  },
  mounted() {
    this.$store.state.isAbsolute = true;
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
};
</script>
