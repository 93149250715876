<template>
    <!--  -->

<div
    class=" w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100  text-white"
    id="sidenav-collapse-main"
    style="overflow: hidden; padding-top: 20px;"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-collapse 
          :class="getRoute() == 'dashboard' ? 'text-dark' : 'text-white'"
          :navText="`${$t('sidenav.main')}`" :to="{ name: 'Dashboard' }">
          <template #icon>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.62254 0.960889C7.48608 0.514923 8.51216 0.514923 9.3757 0.960889L11.6991 2.16079L13.7977 3.28511C14.7642 3.80294 15.3713 4.80685 15.3808 5.90333L15.3991 7.99997L15.3808 10.0966C15.3713 11.1931 14.7642 12.197 13.7977 12.7148L11.6991 13.8391L9.3757 15.039C8.51216 15.485 7.48608 15.485 6.62254 15.039L4.29912 13.8391L2.20055 12.7148C1.23401 12.197 0.626979 11.1931 0.617413 10.0966L0.599121 7.99997L0.617413 5.90333C0.626979 4.80685 1.23401 3.80294 2.20055 3.28511L4.29912 2.16079L6.62254 0.960889Z" :stroke="getRoute() == 'dashboard' ? '#000' : '#fff'" stroke-width="1.2"/>
            <path d="M8.08447 15.0999V8.09995M8.08447 8.09995L14.5845 4.59995M8.08447 8.09995L1.08447 4.59995" :stroke="getRoute() == 'dashboard' ? '#000' : '#fff'" stroke-width="1.2"/>
            <path d="M4.5 2L12 6V9" :stroke="getRoute() == 'dashboard' ? '#000' : '#fff'" stroke-linecap="round"/>
            </svg>

          </template>
        </sidenav-collapse>
      </li>
    
      <div :class="getRoute() == 'cashback' ? '' : 'devi-y'"></div>
      
      <li class="nav-item">
        <sidenav-collapse 
          :class="getRoute() == 'cashback' ? 'text-dark' : 'text-white'"
          :navText="`${$t('sidenav.cashback')}`" :to="{ name: 'Cashback' }">
          <template #icon>
           
              
              <svg v-if="getRoute() == 'cashback'" style="transform: scale(0.65);" width="91" height="112" viewBox="0 0 91 112" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0V112L11.2271 105.327L19.7953 110.332L28.3635 105.327L36.9317 110.332L45.4999 105.327L54.0681 110.332L62.6363 105.327L71.2042 110.332L79.7727 105.327L91 112V0L79.7729 6.67231L71.2045 1.66801L62.6365 6.67231L54.0681 1.66801L45.4999 6.67231L36.9319 1.66801L28.3635 6.67231L19.7953 1.66801L11.2273 6.67231L0 0ZM18.8737 5.76425L27.7539 10.9113L36.634 5.76425L45.5141 10.9113L54.7004 5.76425L63.5808 10.9113L72.4606 5.76425L81.341 10.9113L87.1593 7.47992V104.046L81.341 100.615L72.4606 105.762L63.5808 100.615L54.7004 105.762L45.8205 100.615L36.9403 105.762L28.0602 100.615L19.1798 105.762L10.2997 100.615L4.17553 104.291V7.47992L9.99359 10.9113L18.8737 5.76425ZM10.0795 18.3348V22.5989H32.9473V18.3348H10.0795ZM10.0795 26.3382V30.6023H42.0945V26.3382H10.0795ZM28.3739 39.6551V43.9192H60.3887V39.6551H28.3739ZM12.3663 52.4476V56.7114H44.3811V52.4476H12.3663ZM67.2489 52.4476V56.7114H76.3963V52.4476H67.2489ZM12.3663 63.1078V67.3718H44.3811V63.1078H12.3663ZM67.2489 63.1078V67.3718H76.3963V63.1078H67.2489ZM12.3663 73.7677V78.0317H44.3811V73.7677H12.3663ZM67.2489 73.7677V78.0317H76.3963V73.7677H67.2489ZM62.6755 88.8231V93.0874H80.9697V88.8231H62.6755Z" fill="black"/>
              </svg>
              <svg v-else style="transform: scale(0.65);" width="91" height="112" viewBox="0 0 91 112" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0V112L11.2271 105.327L19.7953 110.332L28.3635 105.327L36.9317 110.332L45.4999 105.327L54.0681 110.332L62.6363 105.327L71.2042 110.332L79.7727 105.327L91 112V0L79.7729 6.67231L71.2045 1.66801L62.6365 6.67231L54.0681 1.66801L45.4999 6.67231L36.9319 1.66801L28.3635 6.67231L19.7953 1.66801L11.2273 6.67231L0 0ZM18.8737 5.76425L27.7539 10.9113L36.634 5.76425L45.5141 10.9113L54.7004 5.76425L63.5808 10.9113L72.4606 5.76425L81.341 10.9113L87.1593 7.47992V104.046L81.341 100.615L72.4606 105.762L63.5808 100.615L54.7004 105.762L45.8205 100.615L36.9403 105.762L28.0602 100.615L19.1798 105.762L10.2997 100.615L4.17553 104.291V7.47992L9.99359 10.9113L18.8737 5.76425ZM10.0795 18.3348V22.5989H32.9473V18.3348H10.0795ZM10.0795 26.3382V30.6023H42.0945V26.3382H10.0795ZM28.3739 39.6551V43.9192H60.3887V39.6551H28.3739ZM12.3663 52.4476V56.7114H44.3811V52.4476H12.3663ZM67.2489 52.4476V56.7114H76.3963V52.4476H67.2489ZM12.3663 63.1078V67.3718H44.3811V63.1078H12.3663ZM67.2489 63.1078V67.3718H76.3963V63.1078H67.2489ZM12.3663 73.7677V78.0317H44.3811V73.7677H12.3663ZM67.2489 73.7677V78.0317H76.3963V73.7677H67.2489ZM62.6755 88.8231V93.0874H80.9697V88.8231H62.6755Z" fill="white"/>
              </svg>



          </template>
        </sidenav-collapse>
      </li>

      <div :class="getRoute() == 'withdraw' ? '' : 'devi-y'"></div>
      <li class="nav-item">
        <sidenav-collapse 
          :class="getRoute() == 'withdraw' ? 'text-dark' : 'text-white'"
          :navText="`${$t('sidenav.withdrawn')}`" :to="{ name: 'Withdraw' }">
          <template #icon>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.5 13.75C9.5 14.72 10.25 15.5 11.17 15.5H13.05C13.85 15.5 14.5 14.82 14.5 13.97C14.5 13.06 14.1 12.73 13.51 12.52L10.5 11.47C9.91 11.26 9.51001 10.94 9.51001 10.02C9.51001 9.18 10.16 8.49001 10.96 8.49001H12.84C13.76 8.49001 14.51 9.27001 14.51 10.24" :stroke="getRoute() == 'withdraw' ? '#000' : '#fff'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 7.5V16.5" :stroke="getRoute() == 'withdraw' ? '#000' : '#fff'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2" :stroke="getRoute() == 'withdraw' ? '#000' : '#fff'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M22 6V2H18" :stroke="getRoute() == 'withdraw' ? '#000' : '#fff'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M17 7L22 2" :stroke="getRoute() == 'withdraw' ? '#000' : '#fff'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

          </template>
        </sidenav-collapse>
      </li>

      <!-- <div :class="getRoute() == 'pay-on' ? '' : 'devi-y'"></div>
      <li class="nav-item">
        <sidenav-collapse 
          :class="getRoute() == 'pay-on' ? 'text-dark' : 'text-white'"
          :navText="`${$t('sidenav.top_up')}`" :to="{ name: 'TopUp' }">
          <template #icon>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.04 13.55C17.62 13.96 17.38 14.55 17.44 15.18C17.53 16.26 18.52 17.05 19.6 17.05H21.5V18.24C21.5 20.31 19.81 22 17.74 22H7.63C7.94 21.74 8.21 21.42 8.42 21.06C8.79 20.46 9 19.75 9 19C9 16.79 7.21 15 5 15C4.06 15 3.19 15.33 2.5 15.88V11.51C2.5 9.44001 4.19 7.75 6.26 7.75H17.74C19.81 7.75 21.5 9.44001 21.5 11.51V12.95H19.48C18.92 12.95 18.41 13.17 18.04 13.55Z"   :stroke="getRoute() == 'pay-on' ? '#000' : '#fff'"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2.5 12.41V7.84004C2.5 6.65004 3.23 5.59 4.34 5.17L12.28 2.17C13.52 1.7 14.85 2.62003 14.85 3.95003V7.75002" :stroke="getRoute() == 'pay-on' ? '#000' : '#fff'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22.5588 13.9702V16.0302C22.5588 16.5802 22.1188 17.0302 21.5588 17.0502H19.5988C18.5188 17.0502 17.5288 16.2602 17.4388 15.1802C17.3788 14.5502 17.6188 13.9602 18.0388 13.5502C18.4088 13.1702 18.9188 12.9502 19.4788 12.9502H21.5588C22.1188 12.9702 22.5588 13.4202 22.5588 13.9702Z" :stroke="getRoute() == 'pay-on' ? '#000' : '#fff'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7 12H14" :stroke="getRoute() == 'pay-on' ? '#000' : '#fff'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9 19C9 19.75 8.79 20.46 8.42 21.06C8.21 21.42 7.94 21.74 7.63 22C6.93 22.63 6.01 23 5 23C3.54 23 2.27 22.22 1.58 21.06C1.21 20.46 1 19.75 1 19C1 17.74 1.58 16.61 2.5 15.88C3.19 15.33 4.06 15 5 15C7.21 15 9 16.79 9 19Z" :stroke="getRoute() == 'pay-on' ? '#000' : '#fff'" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.49171 18.9795H3.51172" :stroke="getRoute() == 'pay-on' ? '#000' : '#fff'" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5 17.5195V20.5095" :stroke="getRoute() == 'pay-on' ? '#000' : '#fff'" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          </template>
        </sidenav-collapse>
      </li> -->
      

      <div :class="getRoute() == 'invest' ? '' : 'devi-y'"></div>

      <li class="nav-item" >
        <sidenav-collapse 
          :class="getRoute() == 'invest' ? 'text-dark' : 'text-white'"
          :navText="`${$t('sidenav.invest_plan') }`" :to="{ name: 'Invest' }">
          <template #icon>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 18.2C14.2091 18.2 16 16.4091 16 14.2C16 11.9908 14.2091 10.2 12 10.2C9.79086 10.2 8 11.9908 8 14.2C8 16.4091 9.79086 18.2 12 18.2Z" :stroke="getRoute() == 'invest' ? '#000' : '#fff'" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.25 12.95V13.88C12.25 14.23 12.07 14.56 11.76 14.74L11 15.2"  :stroke="getRoute() == 'invest' ? '#000' : '#fff'" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.99983 22H15.9998C20.0198 22 20.7398 20.39 20.9498 18.43L21.6998 10.43C21.9698 7.99 21.2698 6 16.9998 6H6.99983C2.72983 6 2.02983 7.99 2.29983 10.43L3.04983 18.43C3.25983 20.39 3.97983 22 7.99983 22Z"  :stroke="getRoute() == 'invest' ? '#000' : '#fff'" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8 6V5.2C8 3.43 8 2 11.2 2H12.8C16 2 16 3.43 16 5.2V6"  :stroke="getRoute() == 'invest' ? '#000' : '#fff'" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M21.6498 11C19.9198 12.26 17.9998 13.14 16.0098 13.64"  :stroke="getRoute() == 'invest' ? '#000' : '#fff'" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2.62012 11.27C4.29012 12.41 6.11012 13.22 8.00012 13.68"  :stroke="getRoute() == 'invest' ? '#000' : '#fff'" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          </template>
        </sidenav-collapse>
      </li>
      
      
      
      
      <div :class="getRoute() == 'tasks' ? '' : 'devi-y'"></div>
      
      
      <li class="nav-item">
        <sidenav-collapse 
          :class="getRoute() == 'tasks' ? 'text-dark' : 'text-white'"
          :navText="`${$t('sidenav.task')}`" :to="{ name: 'Tasks' }">
          <template #icon>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.0001 7.16C17.9401 7.15 17.8701 7.15 17.8101 7.16C16.4301 7.11 15.3301 5.98 15.3301 4.58C15.3301 3.15 16.4801 2 17.9101 2C19.3401 2 20.4901 3.16 20.4901 4.58C20.4801 5.98 19.3801 7.11 18.0001 7.16Z" :stroke="getRoute() == 'tasks' ? '#000' : '#fff'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M16.9704 14.44C18.3404 14.67 19.8504 14.43 20.9104 13.72C22.3204 12.78 22.3204 11.24 20.9104 10.3C19.8404 9.59004 18.3104 9.35003 16.9404 9.59003" :stroke="getRoute() == 'tasks' ? '#000' : '#fff'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.97047 7.16C6.03047 7.15 6.10047 7.15 6.16047 7.16C7.54047 7.11 8.64047 5.98 8.64047 4.58C8.64047 3.15 7.49047 2 6.06047 2C4.63047 2 3.48047 3.16 3.48047 4.58C3.49047 5.98 4.59047 7.11 5.97047 7.16Z" :stroke="getRoute() == 'tasks' ? '#000' : '#fff'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7.00043 14.44C5.63043 14.67 4.12043 14.43 3.06043 13.72C1.65043 12.78 1.65043 11.24 3.06043 10.3C4.13043 9.59004 5.66043 9.35003 7.03043 9.59003" :stroke="getRoute() == 'tasks' ? '#000' : '#fff'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.0001 14.63C11.9401 14.62 11.8701 14.62 11.8101 14.63C10.4301 14.58 9.33008 13.45 9.33008 12.05C9.33008 10.62 10.4801 9.46997 11.9101 9.46997C13.3401 9.46997 14.4901 10.63 14.4901 12.05C14.4801 13.45 13.3801 14.59 12.0001 14.63Z" :stroke="getRoute() == 'tasks' ? '#000' : '#fff'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M9.08973 17.78C7.67973 18.72 7.67973 20.26 9.08973 21.2C10.6897 22.27 13.3097 22.27 14.9097 21.2C16.3197 20.26 16.3197 18.72 14.9097 17.78C13.3197 16.72 10.6897 16.72 9.08973 17.78Z" :stroke="getRoute() == 'tasks' ? '#000' : '#fff'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          </template>
        </sidenav-collapse>
      </li>

      <div :class="getRoute() == 'reports-sign' ? '' : 'devi-y'"></div>
      <li class="nav-item">
        <sidenav-collapse 
          :class="getRoute() == 'reports-sign' ? 'text-dark' : 'text-white'"
          :navText="`${$t('sidenav.report')}`" :to="{ name: 'Reports' }">
          <template #icon>
            <!-- <i class=" fas fa-user"></i> -->
            <svg style="margin:5px;" width="268" height="296" viewBox="0 0 268 296" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M61.1713 39.4469C74.2197 18.3685 97.4397 0 134 0C170.56 0 193.78 18.3685 206.829 39.4469C219.352 59.6765 223.238 83.1101 223.238 98.6667H248.161C260.826 98.6667 270.246 109.26 267.531 120.451L228.367 281.906C226.371 290.136 218.311 296 208.997 296H59.0029C49.6885 296 41.6294 290.136 39.633 281.906L0.46875 120.451C-2.24584 109.26 7.17356 98.6667 19.8387 98.6667H44.7614C44.7614 83.1101 48.6483 59.6765 61.1713 39.4469ZM172.224 56.9774C180.523 70.3842 183.577 87.3141 183.577 98.6667H84.423C84.423 87.3141 87.4769 70.3841 95.7762 56.9774C103.55 44.4194 115.034 35.8788 134 35.8788C152.966 35.8788 164.45 44.4194 172.224 56.9774ZM134 211.283C155.786 211.283 175.271 195.31 188.248 181.3L213.323 203.984C193.887 223.758 165.551 243.279 134 243.279C102.448 243.279 74.1132 223.758 54.6767 203.984L79.7522 181.3C92.7287 195.31 112.214 211.283 134 211.283Z" :fill="getRoute() == 'reports-sign' ? '#000' : '#fff'"/>
            </svg>
          </template>
        </sidenav-collapse>
      </li>


      <div :class="getRoute() == 'profile' ? '' : 'devi-y'"></div>

      <li class="nav-item">
        <sidenav-collapse 
          :class="getRoute() == 'profile' ? 'text-dark' : 'text-white'"
          :navText="`${$t('sidenav.profile')}`" :to="{ name: 'Profile' }">
          <template #icon>
            <!-- <i class=" fas fa-user"></i> -->

            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path 
                d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" 
                :stroke="getRoute() == 'profile' ? '#000' : '#fff'"
                stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"
                />
              <path 
                d="M2 12.88V11.12C2 10.08 2.85 9.22 3.9 9.22C5.71 9.22 6.45 7.94 5.54 6.37C5.02 5.47 5.33 4.3 6.24 3.78L7.97 2.79C8.76 2.32 9.78 2.6 10.25 3.39L10.36 3.58C11.26 5.15 12.74 5.15 13.65 3.58L13.76 3.39C14.23 2.6 15.25 2.32 16.04 2.79L17.77 3.78C18.68 4.3 18.99 5.47 18.47 6.37C17.56 7.94 18.3 9.22 20.11 9.22C21.15 9.22 22.01 10.07 22.01 11.12V12.88C22.01 13.92 21.16 14.78 20.11 14.78C18.3 14.78 17.56 16.06 18.47 17.63C18.99 18.54 18.68 19.7 17.77 20.22L16.04 21.21C15.25 21.68 14.23 21.4 13.76 20.61L13.65 20.42C12.75 18.85 11.27 18.85 10.36 20.42L10.25 20.61C9.78 21.4 8.76 21.68 7.97 21.21L6.24 20.22C5.33 19.7 5.02 18.53 5.54 17.63C6.45 16.06 5.71 14.78 3.9 14.78C2.85 14.78 2 13.92 2 12.88Z" 
                :stroke="getRoute() == 'profile' ? '#000' : '#fff'"
                stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"
              />
            </svg>


          </template>
        </sidenav-collapse>
      </li>

      
      <!-- <div :class="getRoute() == 'reports-sign' ? '' : 'devi-y'"></div> -->

      <!-- <li class="nav-item
        -->
    </ul>
  </div>


  <div class="pt-3  mx-3 mt-3 sidenav-footer">
    <div class="footer-my" style="">
      <!-- <a
        href="https://marketmasterskacom.notion.site/MARKETPLACE-132cd89a42834fd99b0c9bd379cecd0e"
        target="_blank"
        class="text-secondary me-xl-4 me-4 "
        v-if="!isMobile()"
      >
          Инструкция / Документация 
      </a>
      <br> -->
      <!-- v-if="!isMobile()" -->

      <a
          href="https://t.me/help_market_support"
          target="_blank"
          style=""
          class="text-light me-xl-4 me-4 mb-2  my-link"
        >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-1 mb-1 mt-n1">
          <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M12 8V13" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M11.9941 16H12.0031" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        
        <span class=" "> Help </span>
          
        </a>
        <a
            href="https://backend.l1st.online/media/img/politica.pdf"
            target="_blank"
            class="me-xl-4 me-4 mb-2"
            style="display:none;"
        >
            О персональных данных
          </a>
            <div
              @click="logout()"
              style="align-items: center; cursor: pointer; justify-content: center; "
              class="mt-2  me-xl-4 text-light me-4 my-link"
            >
             <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-1 mb-1">
              <path d="M17.4404 14.62L20.0004 12.06L17.4404 9.5" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M9.75977 12.0601H19.9298" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M11.7598 20C7.33977 20 3.75977 17 3.75977 12C3.75977 7 7.33977 4 11.7598 4" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

            
                <span class=" ">Log Out</span>
                
            </div>

          </div>
    
  
  </div>
  




</template>

<style>
.devi-y{
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%,#FAB23C 50%,rgba(255, 255, 255, 0) 100%);
  height: 1px;
  opacity: 0.4;
  margin-top: -1px;
}
.my-link{
  cursor:pointer;
  font-size: 13px;
}
.my-link:hover{
  color: #FAB23C !important;
}
.nav-item.active{
  margin-top: -10px !important;
}
.footer-my{
  background-color: #363636;
  padding: 15px 20px 10px 20px;
  border-radius: 15px;
  margin-bottom: 30px;
}
</style>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";
// import Telegram from "./../../views/components/Telegram.vue";
// import SidenavCard from "./SidenavCard.vue";
// import Shop from "../../components/Icon/Shop.vue";
// import Office from "../../components/Icon/Office.vue";
// import CreditCard from "../../components/Icon/CreditCard.vue";
import { mapMutations,mapGetters,mapActions } from "vuex";

// import Box3d from "../../components/Icon/Box3d.vue";
// import CustomerSupport from "../../components/Icon/CustomerSupport.vue";
// import Document from "../../components/Icon/Document.vue";
// import Spaceship from "../../components/Icon/Spaceship.vue";
// import Settings from "../../components/Icon/Settings.vue";

export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data() {
    return {
      title: "Telos Market",
      controls: "dashboardsExamples",
      isActive: "active",
    };
  },
  components: {
    SidenavCollapse,
    // SidenavCard,
    // Shop,
    // Telegram,
    // Office,
    // CreditCard,
    // Box3d,
    // CustomerSupport,
    // Document,
    // Spaceship,
    // Settings,
  },
  computed: {
     ...mapGetters(["user"]),
  },
  methods: {
    ...mapMutations(["toggleConfigurator"]),
    ...mapActions(["logout"]),
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
  },
};
</script>
